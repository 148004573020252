import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../../../Helper/Context";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI, NewScreenDailyPreventiveWorkCountPopupDataAPI } from "../../../../../../Redux/GardenDashboardSlice/DailyPreventiveWorkSlice";
import { Loading } from "../../../../../../Helper/Loading";

const PendingChecklistPopUp = (props) => {

    const { APIFlag, cancelbtn, YearValue, MonthValue, AssetType, confirmBtnCallBack, deleteRecord, message, heading, Data, Heading } = props
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const [ImagePopUpData, setImagePopUpData] = useState('')
    const [showImagePopup, setshowImagePopup] = useState(false)
    const [ImageHeading, setImageHeading] = useState('')

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setImageHeading(heading)
    }

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            M_AssetID: Data?.m_AssetID,
            M_AssetTypeID: AssetType,
            T_KPIUploadDailyWorkID: Data?.t_kpiUploadDailyWorkID,
            M_WorkTypeID: 0,
            M_UserID: UserID,
            From: '1',
            To: '99999',
            token: token,
            Language: Language.Label,
            Flag: APIFlag
        }
        { Language.Label != '' && dispatch(ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI({ data })) }
    }, [Language.Label])

    const { tableData, isLoading } = useSelector(state => state.ChecklistProgressWiseEmplDailyPendingCompletedSelectData)

    return (
        <>
            <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
                <div className="modal-dialog dialog_width modal-md mt-lg-5 pt-lg-5" role="document" >
                    <div className="modal-content p-3">
                        <div className="modal-header py-0">

                            <h5 className="card-title fw-bold" style={{ paddingLeft: '10px' }} id="exampleModalLongTitle">{Heading}</h5>

                            <button onClick={() => cancelbtn()} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {isLoading && <Loading />}
                        <div className="modal-body mx-0">
                            <div className="row">
                                <div className="table-responsive" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                    <table className="table table-striped ">
                                        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                            <tr className="">

                                                <th className="w-6 px-3">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                <th>{t('EmployeeAndContractor.ChecklistName')}</th>
                                            </tr>

                                        </thead>
                                        {
                                            tableData && tableData.length > 0 ? tableData.map((i,j) =>
                                                <tr>
                                                    <td className="text-center">{j+1}</td>

                                                    <td>{i.m_Daily_Work_CheckList ? i.m_Daily_Work_CheckList : '-'}</td>

                                                </tr>
                                            ) :
                                                <td colSpan={15}>No Found data</td>
                                        }

                                    </table>
                                </div>
                            </div>
                            {/* <hr />
                        <div className="row">
                            <div className="col-12 pt-1 text-center">
                                <button
                                    type='button'
                                    onClick={() => confirmBtnCallBack(deleteRecord)}
                                    className="w-80 btn addbtn btn-success btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u m-2 px-4" data-ktwizard-type="action-next">
                                    <i class="fa-solid fa-floppy-disk pe-1"></i> Submit
                                </button>
                                <button
                                    type="button"
                                    onClick={cancelbtn}
                                    className="w-80  btn addbtn btn-danger btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u m-2 px-4" data-ktwizard-type="action-next">
                                    <i class="fa-solid fa-xmark pe-1"></i> Cancel
                                </button>
                            </div>
                        </div> */}

                        </div>
                    </div>
                </div>
            </div>

        </>);
}

export default PendingChecklistPopUp;
