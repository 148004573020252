import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const NewDashMainCountDataAPI = createAsyncThunk("NewDashMainCountData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_WardID,
        M_MonthID,
        M_UserID,
        M_GD_WorkPeriodID,
        Flag,
        token,
        setGraphLable,
        setCountData,
        Language,
        M_ZoneID
        // handleDashboardGetCount
    } = data

var lables=[]
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_OfficerWisePeriodMainCount_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : '0'}&M_MonthID=${M_MonthID ? M_MonthID : '0'}&M_WardID=${M_WardID ? M_WardID :"0"}&M_ZoneID=${M_ZoneID?M_ZoneID:'0'}&M_UserID=${M_UserID}&Flag=${Flag}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID ? M_GD_WorkPeriodID : '0'}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setCountData(result.data.table)
                for (var i = 0; i < result.data.table.length; i++) {
                    lables.push(result.data.table[i].roleName)
                    // console.log("name", lables)
                    setGraphLable(lables)
                }
                // handleDashboardGetCount(result.data.table)
                return result.data.table
            } else {
                return result.data
            }
        })
})

const NewDashMainCountDataSlice = createSlice({
    name: "NewDashMainCountData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewDashMainCountDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewDashMainCountDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NewDashMainCountDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewDashMainCountDataReducer = NewDashMainCountDataSlice.reducer


// Get Table Data
export const DailyPieChartDataAPI = createAsyncThunk("DailyPieChartData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_WardID,
        M_MonthID,
        M_UserID,
        M_GD_WorkPeriodID,
        Flag,
        token,
        setDailyPieData,
        Language,
        M_ZoneID
        // handleDashboardGetCount
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWorkMainCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID}&M_UserID=${M_UserID}&Flag=${Flag}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setDailyPieData(result.data.table)
                return result.data.table
            } else {
                return result.data
            }
        })
})

const DailyPieChartDataSlice = createSlice({
    name: "DailyPieChartData",
    initialState: {
        isPieLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyPieChartDataAPI.pending, (state, action) => {
            state.isPieLoading = true;
        });
        builder.addCase(DailyPieChartDataAPI.fulfilled, (state, action) => {
            state.isPieLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DailyPieChartDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPieLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DailyPieChartDataReducer = DailyPieChartDataSlice.reducer