import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// Get Table Data
export const GardenDailyWorkBarChartAPI = createAsyncThunk("GardenDailyWorkBarChart", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_WardID,
        M_ZoneID,
        AssignDate,
        M_OfficeID,
        M_DesignationID,
        M_UserID,
        Flag,
        M_GD_WorkPeriodID,
        Language,
        token,
        setContractorBarChart,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_DailyWorkMainCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID}&AssignDate=${AssignDate}&M_OfficeID=${M_OfficeID}&M_DesignationID=${M_DesignationID}&M_UserID=${M_UserID}&Flag=${Flag}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                setContractorBarChart(result.data.table)
                return result.data.table

            } else {
                return result.data.table
            }
        })
})

const GardenDailyWorkBarChartSlice = createSlice({
    name: "GardenDailyWorkBarChart",
    initialState: {
        isCountLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenDailyWorkBarChartAPI.pending, (state, action) => {
            state.isCountLoading = true;
        });
        builder.addCase(GardenDailyWorkBarChartAPI.fulfilled, (state, action) => {
            state.isCountLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GardenDailyWorkBarChartAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isCountLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const GardenDailyWorkBarChartReducer = GardenDailyWorkBarChartSlice.reducer