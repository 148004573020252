import Header from "../../../../Components/Header/Header";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
// import Select from 'react-select'
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../Helper/Context";
import { GardenMasterDeleteAPI, GardenMasterGeoLocationDataAPI, GardenMasterTableDataAPI, GardenMasterTableExportDataAPI } from "../../../../Redux/MasterSlice/GardenMasterSlice";
// import { useSearchParams } from "react-router-dom";
import ViewURL from "../../../../Components/ViewURL/ViewURL";
import GardenPopup from "../GardenPopup/GardenPopup";
import DeletePopup from "../GardenPopup/DeletePopup";
import { Pegination } from "../../../../Components/Pegination/Pegination";
import { WardNameDataDDL } from "../../../../Components/CommonDDL/WardNameDataDDL";
import { Loading } from "../../../../Helper/Loading";
import MapView2 from "../../../../Components/MapView/MapView2";
import { useSearchParams } from "react-router-dom";
import AllGardenMapView from "../../../../Components/MapView/AllGardenMapView";
import { MaintainByDataDDL } from "../../../../Components/CommonDDL/MaintainByDDL";
import { useTranslation } from "react-i18next";
import { toastErrorr, toastSuccesss } from "../../../../Helper/ToastMessage";
import CommonDashboardScreenWardDDL from "../../../../Components/CommonDDL/CommonDashboardScreenWardDDL";
import { GardenMasterExportData } from "./GardenMasterExportData";

export default function MasterData() {

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const [GardenMasterPopupShow, setGardenMasterPopupShow] = useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ShowMap, setShowMap] = React.useState(false)
    const [ShowAllGardenonMap, setShowAllGardenonMap] = React.useState({
        show: false,
        data: ''
    })
    const [LocationData, setLocationData] = React.useState({})
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [rowNo, setrowNo] = useState(1)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [YearValue, setYearValue] = useState(0)
    const [loader, setloader] = useState(false)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)
    const [isSearch, setIsSearch] = useState(false)
    // console.log('flag',flag)

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ZWard = searchParams.get("ZoneName")
    let ZLable = searchParams.get("ZLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")


    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setGardenMasterPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setGardenMasterPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setGardenMasterPopupShow(false)
    }

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        // ID: ZWard && ZWard ? ZWard : '0',
        // Label: ZLable && ZLable ? ZLable : "Select...",
        ID: 0,
        Label: "Select...",

    })
    // console.log('first',ZoneNameDDLID.Label)
    const [MaintainBy, setMaintainBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    // console.log('first',Language.Label)

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }



    const handlerClear = () => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
        setIsClear(!IsClear)
        // setWardName({
        //     ...WardName,
        //     ID: WardName.DDL[0].value,
        //     Label: WardName.DDL[0].label,
        // })
        setWardName({
            ...WardName,
            ID: localStorage.getItem('WardID') ? localStorage.getItem('WardID') : 0,
            Label: localStorage.getItem('WardLabel') ? localStorage.getItem('WardLabel') : "Select...",
        })
        setZoneNameDDLID({
            ...ZoneNameDDLID,
            ID: ZWard && ZWard ? ZWard : '0',
            Label: ZLable && ZLable ? ZLable : "Select...",
        })
        setMaintainBy({
            ...MaintainBy,
            ID: 0,
            Label: 'Select...',
        })
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleSearch = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setIsSearch(!isSearch)
    }

    const handleDeleteApi = (item) => {
        const data = {
            M_AssetID: item.m_AssetID,
            M_UserID: UserID,
            handleIsPost: handleIsPost,
            token: token,
        }
        dispatch(GardenMasterDeleteAPI({ data }))
    }

    const handleLocation = (item) => {
        if (item.assetLatitude && item.assetLongitude) {
            setShowMap(true)
            setLocationData({
                latitude: item.assetLatitude,
                longitude: item.assetLongitude,
                data: item
            })
        }
    }


    const handleCloseMap = () => {
        setShowMap(false)
    }

    // let AllGardenonDataMap = []
    // let showError = false;
    // const handleAllGardenLocation = (data) => {
    //     setloader(true)
    //     data.forEach(item => {
    //         if (item.assetLatitude && item.assetLongitude) {
    //             AllGardenonDataMap = [...AllGardenonDataMap, item];
    //             setTimeout(() => {
    //                 setShowAllGardenonMap({
    //                     show: true,
    //                     data: AllGardenonDataMap
    //                 })
    //                 setloader(false)
    //             }, 1000);
    //         } else {
    //             if (!showError) {
    //                 toastErrorr('Location not found')
    //                 showError = true;
    //             }
    //             setloader(false)
    //         }
    //     });

    // }

    let AllGardenonDataMap = [];
    let showError = (item => item.assetLatitude === null && item.assetLongitude === null) ? true : false;
    let showMessage = false
    const handleAllGardenLocation = (data) => {
        setloader(true);
        if (data) {
            data.forEach(item => {
                if (item.assetLatitude !== null && item.assetLongitude !== null) {
                    AllGardenonDataMap = [...AllGardenonDataMap, item];
                    // showError=false
                    setTimeout(() => {
                        setShowAllGardenonMap({
                            show: true,
                            data: AllGardenonDataMap
                        });
                        if (!showMessage) {
                            // toastSuccesss('All locations found successfully');
                            showMessage = true
                        }
                    }, 1000);
                } else {
                    if (!showError) {
                        toastErrorr('Location not found')
                        showError = true;
                    }
                }
            });
        }
        setTimeout(() => {
            setloader(false);
        }, 1000);
    }
    // console.log("setShowAllGardenonMap", showError, AllGardenonDataMap)

    const handleCloseAllMap = () => {
        setShowAllGardenonMap({
            show: false,
            data: ''
        })
    }
    useEffect(() => {
        const data = {
            M_AssetID: 0,
            UserID: UserID,
            token: token,
            From: From,
            To: To,
            M_AssetTypeID: '1',
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            // M_GD_SectorID: SectorName.ID,
            MaintainByIndicatorAgencyID: MaintainBy.ID,
            Language: Language.Label,
            handleGetExport: handleGetExport
        }
        // if ( MaintainBy.ID != '0') {
        { Language.Label != ''  && dispatch(GardenMasterTableDataAPI({ data })) }
        // }
    }, [To, isSearch, WardName.ID, MaintainBy.ID, ZoneNameDDLID.ID, IsPost, IsClear, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.GardenMasterTableData)

    const handleGetExport = () => {
        const data = {
            M_AssetID: 0,
            UserID: UserID,
            token: token,
            From: From,
            To: '99999',
            M_AssetTypeID: '1',
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            // M_GD_SectorID: SectorName.ID,
            MaintainByIndicatorAgencyID: MaintainBy.ID,
            Language: Language.Label,
        }
        // if ( MaintainBy.ID != '0') {
        { Language.Label != '' && dispatch(GardenMasterTableExportDataAPI({ data })) }
        // }
    }
    // , [To, isSearch, WardName.ID, MaintainBy.ID, ZoneNameDDLID.ID, IsPost, IsClear, Language.Label])

    const { ExportTableData, isExportLoading } = useSelector(state => state.GardenMasterTableExportData)

    useEffect(() => {
        const data = {
            M_AssetID: 0,
            UserID: UserID,
            token: token,
            From: From,
            To: '99999',
            M_AssetTypeID: '1',
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            Language: Language.Label,
        }
        // if ( MaintainBy.ID != '0') {
        { Language.Label != '' && ZoneNameDDLID.ID && WardName.ID && dispatch(GardenMasterGeoLocationDataAPI({ data })) }
        // }
    }, [isSearch, WardName.ID, IsPost, IsClear, ZoneNameDDLID.ID, Language.Label])

    const { locationData, isLoadinglocation } = useSelector(state => state.GardenMasterGeoLocationData)

    // console.log('MapData',MapData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID, ZoneNameDDLID.ID, MaintainBy.ID])

    return (
        <>
            {/* {isLoading && <Loading />} */}
            {loader && <Loading />}
            {isLoadinglocation && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar
                        ProjectTypeId={ProjectTypeId} Language={Language} />
                    <div className="content1" id="">
                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">

                                <div>
                                    <h5 className="ps-1 py-1">{t('Dashboard.Pcmc_Assets.Garden_Name')}</h5>
                                    <div className="float-end butt">

                                        <span className="pe-5 fw-bold Total_count text-dark">
                                            Total Gardens : <span className="fw-bold Total_count">{(tableData && tableData.table) ? tableData.table[0].totalCount : 0}</span>

                                        </span>

                                        {
                                            RoleID === '1' ?
                                                <button className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                                    style={{ color: "white" }}
                                                    onClick={handleAddOnClick}
                                                >
                                                    <text className="ml-2 poppins">Add</text>
                                                </button>
                                                :
                                                ''
                                        }
                                        {
                                            isExportLoading ?
                                                <button type="button" className="btn btn-primary float-end mr-1 mb-1 ml-1" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />Loading</button>
                                                :
                                                ExportTableData && ExportTableData.table && ExportTableData.table.length > 0 &&
                                                <GardenMasterExportData
                                                    ExcelData={ExportTableData}
                                                    name='Garden Master'
                                                // Date={Date}
                                                // searchName={searchName}
                                                />
                                        }

                                    </div>
                                </div>


                                <div className="row m-0">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">
                                            <div className="row rounded2">
                                                <div className="col-12 col-md-12 col-lg-6">
                                                    {/* <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        // SectorName={SectorName}
                                                        // setSectorName={setSectorName}
                                                        Language={Language}
                                                        Flag='ward'
                                                    /> */}

                                                    {/* <DashBoardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        // WLable={WLable}
                                                        // HWard={HWard}
                                                        // flag={flag}
                                                        Language={Language}
                                                        M_ZoneID={1}
                                                        // Flag='ward'
                                                        Flag='WardDDL'
                                                    /> */}
                                                    <CommonDashboardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        ZLable={ZLable}
                                                        ZWard={ZWard}
                                                        Language={Language}
                                                        ZoneNameDDLID={ZoneNameDDLID}
                                                        setZoneNameDDLID={setZoneNameDDLID}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12 col-lg-3">
                                                    <MaintainByDataDDL
                                                        MaintainBy={MaintainBy}
                                                        setMaintainBy={setMaintainBy}
                                                        Language={Language}
                                                        M_AssetTypeID='1'
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-1 mt-lg-4 mt-md-4" >
                                                    {/* <button type="button" className="btn-search btn btn btn-secondary mr-1"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </button> */}
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handlerClear} >
                                                        Clear</button>
                                                </div>

                                                {/* {
                                                    locationData && locationData.table && locationData.table.length > 0 ? */}
                                                <div className="col-12 col-lg-2 mt-lg-4 mt-md-4 float-end pe-5" >

                                                    <button type="button" class="btn position-relative  d-flex float-end btn-outline-Orgprimary"
                                                        onClick={() => handleAllGardenLocation(locationData.table)}>
                                                        <i style={{ cursor: 'pointer', fontSize: '1rem' }} title='Track'
                                                            className="fa-solid fa-location-dot mr-3"
                                                        ></i>
                                                        Map
                                                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                            {(locationData && locationData.table) && locationData.table[0].totalCount}
                                                        </span>
                                                    </button>
                                                </div>
                                                {/* :
                                                        <></>
                                                } */}

                                            </div>
                                            <div className="row mob_table table-responsive pt-1">
                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Ward_no')}</th>
                                                            {/* <th>{t('Dashboard.Pcmc_Assets.Sector_Name')}</th> */}
                                                            <th>{t('Dashboard.Pcmc_Assets.Area_Name')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Garden_Name')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Area_sq')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Maintain_By')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Image')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Track')}</th>
                                                            {
                                                                RoleID == '1' ?
                                                                    <th>{t('Dashboard.Pcmc_Assets.Action')}</th>
                                                                    : ''
                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>{item.wardName ? item.wardName : '-'}</td>
                                                                    <td>{item.areaName ? item.areaName : '-'}</td>
                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    <td style={{ textAlign: "right" }}>{item.areaInSquareMetre ? item.areaInSquareMetre.toFixed(2) : '0.00'}</td>
                                                                    <td>{item.indicatorName ? item.indicatorName : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.assetPhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.assetPhoto)}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.assetLatitude == null && item.assetLongitude == null) ?
                                                                                <span>-</span> :
                                                                                <>
                                                                                    <i style={{ cursor: 'pointer', fontSize: '1rem' }} title='Track'
                                                                                        className="fa-solid fa-location-dot"
                                                                                        onClick={() => handleLocation(item)}
                                                                                    ></i>
                                                                                </>
                                                                        }

                                                                    </td>
                                                                    {
                                                                        RoleID == '1' ?
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <span>
                                                                                    <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => handleEditOnClick(item)}
                                                                                    >
                                                                                    </i>
                                                                                </span>
                                                                                <span>
                                                                                    <DeletePopup
                                                                                        open={DeletePopupShow}
                                                                                        item={item}
                                                                                        handleDeleteApi={handleDeleteApi}
                                                                                    />
                                                                                </span>
                                                                            </td>
                                                                            : ''}
                                                                </tr>
                                                            ) :
                                                                <>No Found data</>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading="Asset Photo"
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }

                {
                    ShowAllGardenonMap.show &&
                    <AllGardenMapView
                        ShowAllGardenonMap={ShowAllGardenonMap}
                        handleCloseAllMap={handleCloseAllMap}
                    // TableData={tableData && tableData.table}
                    />
                }
                {
                    ShowMap ?
                        <MapView2
                            LoactionData={LocationData}
                            ShowMap={ShowMap}
                            handleCloseMap={handleCloseMap}
                            currEmployeeName=''
                            TableData={locationData && locationData.table}
                        // ShowAllGardenonMap={ShowAllGardenonMap}
                        />
                        : ''
                }
                {
                    GardenMasterPopupShow ?
                        <GardenPopup
                            open={GardenMasterPopupShow}
                            GardenMasterFlag='GardenMaster'
                            popupHeading="Garden Master"
                            AreaMeter={t('Dashboard.Pcmc_Assets.Area')}
                            M_AssetTypeID='1'
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        // Language={Language}
                        />
                        : <></>
                }
            </div >
        </>
    )
}