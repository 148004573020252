
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from "moment";
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import { useTranslation } from 'react-i18next';
import { CommMasterDepartmentDDL } from '../../../../Components/CommonDDL/CommMasterDepartmentDDL';
import { DailyWorkSheetPDFDataAPI, DailyWorkSheetTableDataAPI, DailyWorkSheetTablePDFDataAPI } from '../../../../Redux/CommDashbaord/DailyWorkSheet/DailyWorkSheetSlice';
import { Loading } from '../../../../Helper/Loading';
import { Pegination } from '../../../../Components/Pegination/Pegination';
import ViewURL from '../../../../Components/ViewURL/ViewURL';
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from '../../../../Helper/Context';
import { DepartmentWiseEmployeeDataDDL } from '../../../../Components/CommonDDL/DepartmentWiseEmployeeDDL';
import { toastErrorr } from '../../../../Helper/ToastMessage';
import { DepartmentWiseDesignationDataDDL } from '../../../../Components/CommonDDL/DepartmentWiseDesignationDDL';
import { DesignationDDL } from '../../../../Components/CommonDDL/DesignationDDL';
import { EmployeeDataDDL } from '../../../../Components/CommonDDL/EmployeeDataDDL';
import { DailyWorkSheetDesignationDDL } from '../../../../Components/CommonDDL/DailyWorkSheetDesignationDDL';

export default function DailyWorkSheetTable() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let FlagName = searchParams.get("Flag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [Department, setDepartment] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...',
    })

    const [Designation, setDesignation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [DailyWorkSheetDesignation, setDailyWorkSheetDesignation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [EmployeeDDL, setEmployeeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [DepartmentWiseDesignationDDL, setDepartmentWiseDesignationDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...',
    })

    const [DepartmentWiseEmployeeDDL, setDepartmentWiseEmployeeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...',
    })

    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])



    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [isSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [Heading, setHeading] = React.useState(false)
    const [FromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [PDFUrl, setPDFUrl] = useState('')

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        // setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setFromDate(moment().format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setDepartment({
            ...Department,
            ID: Department.DDL[0].value,
            Label: Department.DDL[0].label,
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: 'Select...',
        })
        setEmployeeDDL({
            ...EmployeeDDL,
            ID: 0,
            Label: 'Select...',
        })
        setDailyWorkSheetDesignation({
            ...DailyWorkSheetDesignation,
            ID: 0,
            Label: 'Select...',
        })
    }

    // const handlePost = () => {
    //     setIsPost(!IsPost)
    // }

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setHeading(heading)
    }

    useEffect(() => {
        const data = {
            // FinancialYearID: YearValue,
            FromDate: FromDate,
            ToDate: ToDate,
            M_AssetTypeID: AssetType.ID,
            M_DepartmentID: Department.ID,
            OfficerNameID: EmployeeDDL.ID,
            M_DesignationID: DailyWorkSheetDesignation.ID,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
            // handleGetPdfUrl: handleGetPdfUrl
            // Flag: ApiFlag,

        }
        { Department.ID && dispatch(DailyWorkSheetTableDataAPI({ data })) }
    }, [IsPost, FromDate, ToDate, To, IsClear, Department.ID, AssetType.ID, EmployeeDDL.ID, DailyWorkSheetDesignation.ID,])

    const { tableData, isLoading } = useSelector(state => state.DailyWorkSheetTableData)

    const handleGetPDFReportUrl = (pdfUrl) => {
        if (pdfUrl && (pdfUrl != '' || pdfUrl != null)) {
            window.open(pdfUrl)
        } else {
            toastErrorr("PDF not found")
        }
    }

    const handlePDFReport = () => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_AssetTypeID: AssetType.ID,
            M_DepartmentID: Department.ID,
            OfficerNameID: EmployeeDDL.ID,
            M_DesignationID: DailyWorkSheetDesignation.ID,
            M_UserID: UserID,
            token: token,
            Flag: 'Web',
            From: From,
            To: '99999',
            handleGetPDFReportUrl: handleGetPDFReportUrl
        }
        dispatch(DailyWorkSheetPDFDataAPI({ data }))
    }
    const { isExportLoading } = useSelector(state => state.DailyWorkSheetPDFData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [FromDate, ToDate, Department.ID, AssetType.ID, EmployeeDDL.ID, DailyWorkSheetDesignation.ID])

    return (
        <>
            {isLoading && <Loading />}
            {isExportLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId}
                        Language={Language} />
                    <div id="" className='content1'>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1">Daily Attendance Sheet</h5>
                                </div>
                                <div className='col-4'>
                                    <button type="button" className="btn btn-outline-primary float-end mr-1 mb-2 pt-1 pb-1 pl-3 pr-3"
                                        onClick={handlePDFReport}
                                    >PDF</button>
                                </div>

                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>{t('Dashboard.Work_Assigned_by_Officials.From_Date')} :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Work_Assigned_by_Officials.To_Date')} :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <CommMasterDepartmentDDL
                                                        Department={Department}
                                                        setDepartment={setDepartment}
                                                        Flag='DailyAttendanceSheet'
                                                    />

                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-2">
                                                    <DepartmentWiseDesignationDataDDL
                                                        Designation={DepartmentWiseDesignationDDL}
                                                        setDepartmentWiseDesignationDDL={setDepartmentWiseDesignationDDL}
                                                        Department={Department}
                                                    />

                                                </div>
                                                <div className="col-12 col-lg-2 col-md-6">
                                                    <DepartmentWiseEmployeeDataDDL
                                                        DepartmentWiseEmployeeDDL={DepartmentWiseEmployeeDDL}
                                                        setDepartmentWiseEmployeeDDL={setDepartmentWiseEmployeeDDL}
                                                        Department={Department}
                                                    />

                                                </div> */}

                                                {/* <div className="col-12 col-md-5 col-lg-2">
                                                    <DesignationDDL
                                                        Designation={Designation}
                                                        setDesignation={setDesignation}
                                                        Department={Department}
                                                        IsPost={IsPost}
                                                    />
                                                </div> */}

                                                <div className="col-12 col-md-5 col-lg-2">
                                                    <DailyWorkSheetDesignationDDL
                                                        DailyWorkSheetDesignation={DailyWorkSheetDesignation}
                                                        setDailyWorkSheetDesignation={setDailyWorkSheetDesignation}
                                                        DDLFlag='Daily_Schedule_Wise'
                                                        IsPost={IsPost}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-5 col-lg-2">
                                                    <EmployeeDataDDL
                                                        EmployeeDDL={EmployeeDDL}
                                                        setEmployeeDDL={setEmployeeDDL}
                                                        Department={Department}
                                                        DailyWorkSheetDesignation={DailyWorkSheetDesignation}
                                                        // Designation={Designation}
                                                        IsPost={IsPost}
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-2 col-md-4 mt-lg-4 d-md-flex" >
                                                    {/* <button type="button"
                                                        className="btn-clear btn btn-primary mx-2"
                                                    // onClick={handleSearch}
                                                    >
                                                        Search</button> */}
                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Department')}</th>
                                                            <th>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')}</th>
                                                            <th>{t('AdminDashboard.Admin_Sidebar_Name.Designation')}</th>
                                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Officer_Name')}</th>
                                                            <th>{t('Dashboard.Titles.Schedule_Date')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Asset_Name')}</th>
                                                            <th>{t('Dashboard.Titles.Attendance')}</th>
                                                            <th>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Work_Description')}</th>
                                                            <th>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Photo')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>{item.departmentName ? item.departmentName : '-'}</td>
                                                                    <td>{item.zoneName ? item.zoneName : '-'}</td>
                                                                    <td>{item.designationName ? item.designationName : '-'}</td>
                                                                    <td>{item.officerName ? item.officerName : '-'}</td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>{item.workDate ? moment(item.workDate).format('DD-MM-YYYY') : '-'}</td>
                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    <td style={{ color: item.attendanceFlag == "A" ? 'red' : 'blue' }}>{item.attendanceFlag ? item.attendanceFlag == "A" ? "Absent" : "Present" : '-'}</td>
                                                                    <td>{item.remark ? item.remark : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.dailyWorkPhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => (
                                                                                            handleShowImage(item.dailyWorkPhoto),
                                                                                            setHeading("Assign Work Photo")
                                                                                        )}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                                <td colSpan={15}>No Found data</ td>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--  --> */}


                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }
            </div>
        </>

    )
}