

import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from "moment";
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import DeletePopup from '../GardenPopup/DeletePopup';
import { AssetTypeDataDDL } from '../../../../Components/CommonDDL/AssetTypeDataDDL';
import { PlayEquipmentMasterDeleteAPI, PlayEquipmentMasterQrReportDataAPI, PlayEquipmentMasterTableDataAPI, PlayEquipmentMasterTableExportDataAPI } from '../../../../Redux/MasterSlice/PlayEquipmentSlice';
import { Loading } from '../../../../Helper/Loading';
import { Pegination } from '../../../../Components/Pegination/Pegination';
import ViewURL from '../../../../Components/ViewURL/ViewURL';
import AllGardenMapView from '../../../../Components/MapView/AllGardenMapView';
import MapView2 from '../../../../Components/MapView/MapView2';
import { toastErrorr, toastInfo } from '../../../../Helper/ToastMessage';
import PlayEquipmentPopup from '../GardenPopup/PlayEquipmentPopup';
import { ContractorNameDDL } from '../../../../Components/CommonDDL/ContractorNameDDL';
import { EquipmentNameDDL } from '../../../../Components/CommonDDL/EquipmentNameDDL';
import { jsPDF } from "jspdf";
import { WardWiseAssetNameDataDDL } from '../../../../Components/CommonDDL/WardWiseAssetDDL';
import CommonDashboardScreenWardDDL from '../../../../Components/CommonDDL/CommonDashboardScreenWardDDL';
import ViewQR from '../../../../Components/ViewQR/ViewQR';
import { ContractorWiseAssetDDL } from '../../../../Components/CommonDDL/WorkTypeWiseContractorDDL';
import PlayEquipmentAllGardenMapView from '../../../../Components/MapView/PlayEquipmentMapView';
import PlaySingleMapView from '../../../../Components/MapView/PlayEquipmentSingleMapView';
import { PlayEquipmentsMasterExportData } from './PlayEquipmentExportData';



export default function PlayEquipmentData() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()


    const [PlayEquipmentPopupShow, setPlayEquipmentPopupShow] = useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [ShowMap, setShowMap] = React.useState(false)
    const [ShowAllGardenonMap, setShowAllGardenonMap] = React.useState({
        show: false,
        data: ''
    })

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ZWard = searchParams.get("ZoneName")
    let ZLable = searchParams.get("ZLable")
    let FlagName = searchParams.get("Flag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    // console.log(FlagName)

    const [Role, setRole] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: ZWard && ZWard ? ZWard : '0',
        Label: ZLable && ZLable ? ZLable : "Select...",
        // ID: 0,
        // Label: "Select...",

    })

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [LocationData, setLocationData] = React.useState({})

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [MapData, setMapData] = useState('')
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [Heading, setHeading] = React.useState(false)
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [loader, setloader] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [QRPDF, setQRPDF] = useState('')

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    // console.log('AssetType',AssetType.value)
    const [WardWiseAssetName, setWardWiseAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    console.log('WardWiseAssetName', WardWiseAssetName)
    const [EquipmentName, setEquipmentName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [SupplierName, setSupplierName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [ContractorWiseAsset, setContractorWiseAsset] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    // const [ZoneNameDDLID, setZoneNameDDLID] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",

    // })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setPlayEquipmentPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setPlayEquipmentPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setPlayEquipmentPopupShow(false)
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID])

    // const handleDownloadQR1 = () => {
    //     if (QRPDF != '') {
    //         window.open(QRPDF)
    //     } else {
    //         toastInfo('QR file not found ...')
    //     }
    // }
    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setWardName({
            ...WardName,
            ID: localStorage.getItem('WardID') ? localStorage.getItem('WardID') : 0,
            Label: localStorage.getItem('WardLabel') ? localStorage.getItem('WardLabel') : "Select...",
        })
        // setAssetType({
        //     ...AssetType,
        //     ID: AssetType.DDL[0].value,
        //     Label: AssetType.DDL[0].label,
        // })
        setWardWiseAssetName({
            ...WardWiseAssetName,
            ID: 0,
            Label: 'Select...',
        })
        setEquipmentName({
            ...EquipmentName,
            ID: 0,
            Label: 'Select...'
        })
        setContractorWiseAsset({
            ...ContractorWiseAsset,
            ID: 0,
            Label: 'Select...'
        })
        setContractorName({
            ...ContractorName,
            ID: 0,
            Label: 'Select...'
        })
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }
    }

    const handleLocation = (item) => {
        setMapData(item)
        if (item.equipmentLatitude && item.equipmentLongitude) {
            setShowMap(true)
            setLocationData({
                latitude: item.equipmentLatitude,
                longitude: item.equipmentLongitude
            })
        }
    }


    const handleCloseMap = () => {
        setShowMap(false)
    }

    let AllGardenonDataMap = []
    let showError = false;
    const handleAllGardenLocation = (data) => {
        setloader(true)
        data.forEach(item => {
            if (item.equipmentLatitude && item.equipmentLongitude) {
                AllGardenonDataMap = [...AllGardenonDataMap, item];
                setTimeout(() => {
                    setShowAllGardenonMap({
                        show: true,
                        data: AllGardenonDataMap
                    })
                    setloader(false)
                }, 1000);
            } else {
                if (!showError) {
                    toastErrorr('Location not found')
                    showError = true;
                }
                setloader(false)
            }
        });

    }

    const handleCloseAllMap = () => {
        setShowAllGardenonMap({
            show: false,
            data: ''
        })
    }

    useEffect(() => {
        // console.log(FinancialYear, FinancialYearID, FinancialYearLabel)
        const data = {
            M_AssetWiseEquipmentID: EquipmentName.ID,
            M_Contractor_ID: ContractorWiseAsset.ID,
            M_AssetID: WardWiseAssetName.ID,
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            UserID: UserID,
            Language: Language.Label,
            From: From,
            To: To,
            token: token,
            setQRPDF: setQRPDF,
            handleGetExport: handleGetExport
        }
        // if (WardName.ID) {
        { Language.Label != '' && dispatch(PlayEquipmentMasterTableDataAPI({ data })) }
        // }

    }, [ContractorWiseAsset.ID, ZoneNameDDLID.ID, EquipmentName.ID, To, WardWiseAssetName.ID, WardName.ID, IsPost, IsClear, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.PlayEquipmentMasterTableData)


    const handleGetExport = () => {
        // console.log(FinancialYear, FinancialYearID, FinancialYearLabel)
        const data = {
            M_AssetWiseEquipmentID: EquipmentName.ID,
            M_Contractor_ID: ContractorName.ID,
            M_AssetID: WardWiseAssetName.ID,
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            UserID: UserID,
            Language: Language.Label,
            From: From,
            To: '99999',
            token: token,
            setQRPDF: setQRPDF

        }
        // if (WardName.ID) {
        { Language.Label != ''  && dispatch(PlayEquipmentMasterTableExportDataAPI({ data })) }
        // }

    }
    // , [ContractorName.ID, ZoneNameDDLID.ID, EquipmentName.ID, To, WardWiseAssetName.ID, WardName.ID, IsPost, IsClear, Language.Label])

    const { ExportTableData, isExportLoading } = useSelector(state => state.PlayEquipmentMasterTableExportData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID, AssetType.ID, EquipmentName.ID, ContractorName.ID, ZoneNameDDLID.ID, From, To])

    const handleDeleteApi = (item) => {
        const data = {
            M_AssetWiseEquipmentID: item.m_AssetWiseEquipmentID,
            M_UserID: UserID,
            handleIsPost: handleIsPost,
            token: token,
        }
        dispatch(PlayEquipmentMasterDeleteAPI({ data }))
    }

    const handleDownloadQR = (qR_CodePath, assetName_English, equipmentName, equipmentNumber) => {

        const GardenName = `Garden Name : ${assetName_English}`
        const EquipmentName = `Equipment Name : ${equipmentName}`
        const EquipmentNo = `Equipment Number : ${equipmentNumber}`

        const doc = new jsPDF();
        var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        doc.addImage(qR_CodePath, 'JPG', 15, 40, 180, 180);
        doc.text(GardenName, pageWidth / 2, pageHeight / 1.35, { align: 'center' });
        doc.text(EquipmentName, pageWidth / 2, pageHeight / 1.30, { align: 'center' });
        doc.text(EquipmentNo, pageWidth / 2, pageHeight / 1.25, { align: 'center' });
        doc.save(`${equipmentName}.pdf`);
    }

    const [ShowQRPopUp, setShowQRPopUp] = useState({
        data: '',
        show: false
    })

    const handleShowQR = (item) => {
        setShowQRPopUp({
            ...ShowQRPopUp,
            data: item,
            show: true
        })
        // if (imgQR) {
        //     window.open(imgQR)
        // } else {
        //     toastErrorr('QR not found ...')
        // }
    }

    const handleCloseQR = () => {
        setShowQRPopUp({
            ...ShowQRPopUp,
            data: '',
            show: false
        })
    }

    const handleGetQrReportUrl = (pdfUrl) => {
        if (pdfUrl && pdfUrl != '') {
            window.open(pdfUrl)
        } else {
            toastErrorr("PDF not found")
        }
    }


    const handleQrReport = () => {
        const data = {
            M_AssetWiseEquipmentID: EquipmentName.ID,
            M_Contractor_ID: ContractorName.ID,
            M_AssetID: WardWiseAssetName.ID,
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            UserID: UserID,
            Language: Language.Label,
            From: From,
            To: '99999',
            token: token,
            handleGetQrReportUrl: handleGetQrReportUrl
        }
        { Language.Label != ''  && dispatch(PlayEquipmentMasterQrReportDataAPI({ data })) }
    }
    const { QrReportData, isQrReportLoading } = useSelector(state => state.PlayEquipmentMasterQrReportData)


    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId}
                        Language={Language} />
                    <div id="" className='content1'>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-12'>
                                    <div className='col-7'>
                                        <h5 className="ps-1 py-1">Play Equipments</h5>
                                    </div>
                                    <div className="col-5 float-end butt">

                                        <span className=" fw-bold text-end Total_count text-dark">
                                            Total Play Equipments : <span className="fw-bold Total_count">{(tableData && tableData.table) ? tableData.table[0].totalCount : 0}</span>
                                        </span>

                                        {/* <button type="button"
                                            style={{ background: '#254462', borderColor: '#254462' }}
                                            className="btn btn-primary float-end mr-1 ml-1 "
                                            onClick={() => handleQrReport()}
                                        >
                                            QR Download
                                        </button> */}

                                        {
                                            isQrReportLoading ?
                                                <button type="button" style={{ width: '7em' }} className="btn btn-danger float-end d-flex" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px", marginTop: '3px' }}
                                                    />Loading</button>
                                                :
                                                // PDFtableData && PDFtableData.table && PDFtableData.table.length > 0 ?
                                                <>
                                                    {/* <button type="button" className="btn btn-primary float-end mr-1">Export</button> */}
                                                    <button type="button"
                                                        style={{ background: '#254462', borderColor: '#254462' }}
                                                        className="btn btn-primary float-end mr-1 ml-1 "
                                                        onClick={() => handleQrReport()}
                                                    >
                                                        QR Download
                                                    </button>
                                                </>
                                            // :
                                            // ''
                                        }

                                        {
                                            RoleID === '1' ?
                                                <button className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                                    style={{ color: "white" }}
                                                    onClick={handleAddOnClick}
                                                >
                                                    <text className="ml-2 poppins">Add</text>
                                                </button>
                                                :
                                                ''
                                        }

                                        {
                                            isExportLoading ?
                                                <button type="button" className="btn btn-primary float-end mr-1 mb-1 ml-1" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />Loading</button>
                                                :
                                                ExportTableData && ExportTableData.table && ExportTableData.table.length > 0 &&
                                                <PlayEquipmentsMasterExportData
                                                    ExcelData={ExportTableData}
                                                    name='Play Equipment'
                                                // Date={Date}
                                                // searchName={searchName}
                                                />
                                        }

                                    </div>
                                </div>
                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-12 col-lg-4">
                                                    <CommonDashboardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        ZLable={ZLable}
                                                        ZWard={ZWard}
                                                        Language={Language}
                                                        ZoneNameDDLID={ZoneNameDDLID}
                                                        setZoneNameDDLID={setZoneNameDDLID}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <WardWiseAssetNameDataDDL
                                                        WardWiseAssetName={WardWiseAssetName}
                                                        setWardWiseAssetName={setWardWiseAssetName}
                                                        WardName={WardName}
                                                        AssetType='1'
                                                        M_WorkTypeID='5'
                                                        M_ZoneID={ZoneNameDDLID}
                                                        M_RoleID={RoleID}
                                                        M_GD_WorkPeriodID='0'
                                                        // M_AssetTypeID={1}
                                                        Language={Language}
                                                        Flag='Master'
                                                        Flag2='Play'
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <EquipmentNameDDL
                                                        EquipmentName={EquipmentName}
                                                        setEquipmentName={setEquipmentName}
                                                        Language={Language}
                                                        IsPost={IsPost}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <ContractorWiseAssetDDL
                                                        ContractorWiseAsset={ContractorWiseAsset}
                                                        setContractorWiseAsset={setContractorWiseAsset}
                                                        Language={Language}
                                                        rowData={rowData}
                                                        WardWiseAssetName={WardWiseAssetName}
                                                        RoleID={RoleID}
                                                        Flag='Master'
                                                        // Flag={Flag}
                                                        // M_WorkTypeID={4}
                                                        WorkType={4}
                                                        // Flag1='ContractorWiseAsset'
                                                        ZoneNameDDLID={ZoneNameDDLID}
                                                        AssetType={AssetType}
                                                        Flag2='Play'
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-2">
                                                    <ContractorNameDDL
                                                        ContractorName={ContractorName}
                                                        setContractorName={setContractorName}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                        Flag1='SupplierName'
                                                        Flag='Sport'
                                                    // Flag='Master'
                                                    />
                                                </div> */}
                                                <div className="col-12 col-lg-2 col-md-4 mt-lg-4 d-md-flex" >

                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr.No</th>
                                                            <th>Prabhag No.</th>
                                                            <th style={{ width: "15em" }}>Garden Name</th>
                                                            {/* <th>{t('Dashboard.Pcmc_Assets.Sector_Name')}</th> */}
                                                            <th>Equipment Name</th>
                                                            <th style={{ textAlign: "center" }}>Equipment No.</th>
                                                            <th className="equcenter" style={{ textAlign: "center" }}>Equipment Installation Date</th>
                                                            <th>Supplier Name</th>
                                                            <th style={{ textAlign: "center" }}>Warranty Period (Year)</th>
                                                            <th>Photo</th>
                                                            <th>QR Code</th>
                                                            <th>Track</th>
                                                            {
                                                                RoleID == '1' ?
                                                                    <th>Action</th>
                                                                    : ''
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td style={{ whiteSpace: "nowrap" }}>{item.wardName ? item.wardName : '-'}</td>
                                                                    {/* <td></td> */}
                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    <td>{item.equipmentName ? item.equipmentName : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>{item.equipmentNumber ? item.equipmentNumber : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>{item.equipmentInstallationDate ? moment(item.equipmentInstallationDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    <td>{item.contractorName ? item.contractorName : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>{item.warrantyPeriod ? item.warrantyPeriod : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.equipmentPhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.equipmentPhoto)}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.qR_CodePath == null || item.qR_CodePath == '') ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View QR'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowQR(item)}
                                                                                    ></i>

                                                                                    {/* <i className="mx-2 fa-solid fa-download" title='Download QR'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleDownloadQR(item.qR_CodePath, item.assetName_English, item.equipmentName, item.equipmentNumber)}
                                                                                    ></i> */}
                                                                                </>
                                                                            // <span title='Click to Download QR Code' className='cursor-pointer' onClick={() => handleDownloadQR(item.qR_CodePath, item.assetName_English, item.equipmentName, item.equipmentNumber)}>
                                                                            //     <img style={{ width: "85%" }} src={item.qR_CodePath ? item.qR_CodePath : '-'} />
                                                                            // </span>
                                                                        }
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.equipmentLatitude == null && item.equipmentLongitude == null) ?
                                                                                <span>-</span> :
                                                                                <>
                                                                                    <i style={{ cursor: 'pointer', fontSize: '1rem' }} title='Track'
                                                                                        className="fa-solid fa-location-dot"
                                                                                        onClick={() => handleLocation(item)}
                                                                                    ></i>
                                                                                </>
                                                                        }

                                                                    </td>
                                                                    {
                                                                        RoleID == '1' ?
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <span>
                                                                                    <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => handleEditOnClick(item)}
                                                                                    >
                                                                                    </i>
                                                                                </span>
                                                                                <span>
                                                                                    <DeletePopup
                                                                                        open={DeletePopupShow}
                                                                                        item={item}
                                                                                        handleDeleteApi={handleDeleteApi}
                                                                                    />
                                                                                </span>
                                                                            </td>
                                                                            : ''
                                                                    }
                                                                </tr>
                                                            ) :
                                                                <>No Found data</>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--  --> */}


                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading="Asset Photo"
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }

                {
                    ShowQRPopUp.show ?
                        <ViewQR
                            Heading="Equipment QR"
                            ShowQRPopUp={ShowQRPopUp}
                            handleClose={handleCloseQR}
                            handleDownloadQR={handleDownloadQR}
                        />
                        : ''
                }


                {
                    ShowAllGardenonMap.show &&
                    <PlayEquipmentAllGardenMapView
                        ShowAllGardenonMap={ShowAllGardenonMap}
                        handleCloseAllMap={handleCloseAllMap}
                        TableData={tableData && tableData.table}
                    />
                }
                {
                    ShowMap ?
                        <PlaySingleMapView
                            LoactionData={LocationData}
                            ShowMap={ShowMap}
                            handleCloseMap={handleCloseMap}
                            currEmployeeName=''
                            MapData={MapData}
                        />
                        : ''
                }
                {
                    PlayEquipmentPopupShow ?
                        <PlayEquipmentPopup
                            open={PlayEquipmentPopupShow}
                            GardenMasterFlag='GardenMaster'
                            popupHeading="Play Equipment Master"
                            AreaMeter={t('Dashboard.Pcmc_Assets.Area')}
                            M_AssetTypeID='1'
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        // Language={Language}
                        />
                        : <></>
                }
            </div>
        </>

    )
}