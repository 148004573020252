
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr } from "../../Helper/ToastMessage";


export const WorkTypeButtonGetAPI = createAsyncThunk("WorkTypeButtonGet", async ({ data }) => {
    const {
        M_UserID,
        Flag,
        token,
        Language,
        setWorkTypeBtnData,
        setWorkActiveButton,
        handleWorkButtonData,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    
    return fetch(`${BaseUrl}/CommonDLLData/Get_DB_DDL_M_WorkType_Select?Flag=${Flag}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
               
                handleWorkButtonData(result.data.table)
                return result.data.table
            } else {
                return result
            }
        })
})

const WorkTypeButtonGetSlice = createSlice({
    name: "WorkTypeButtonGet",
    initialState: {
        isDDLLoading: false,
        WorkTypeBtnData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkTypeButtonGetAPI.pending, (state, action) => {
            state.isDDLLoading = true;
        });
        builder.addCase(WorkTypeButtonGetAPI.fulfilled, (state, action) => {
            state.isDDLLoading = false;
            state.WorkTypeBtnData = action.payload;
        });
        builder.addCase(WorkTypeButtonGetAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDDLLoading = false;
            state.isError = true;
            state.WorkTypeBtnData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WorkTypeButtonGetReducer = WorkTypeButtonGetSlice.reducer