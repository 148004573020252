import Header from "../../../Components/Header/Header"
import Sidebar from "../../../Components/Sidebar/Sidebar"
import GardenMasterCardManage from "./GarderDashboardComponent/GardenMasterCard/GardenMasterCardManage"
import React, { useEffect, useState } from 'react'
import { useAuthState } from "../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { GardenDashMainCountDataAPI } from "../../../Redux/MainCountSlice/GardenMainCount"
import { Loading } from "../../../Helper/Loading"
import { useSearchParams } from "react-router-dom"
import CommonDashboardWardNameDDL from "./CommonDashboardWardNameDDL"
import NewGardenWorkProgressByContractorCardManage from "./NewDashBoardComponents/HorticultureWorkProgress/NewGardenWorkProgressByContractorCardManage"
import NewCivilWorkProgressByContractorCardManage from "./NewDashBoardComponents/CivilWorkProgress/NewCivilWorkProgressByContractorCardManage"
import NewElectricalWorkProgressByContractorCardManage from "./NewDashBoardComponents/ElectricalWorkProgress/NewElectricalWorkProgressByContractorCardManage"
import NewGardenReopenWorkProgressCardManage from "./NewDashBoardComponents/HorticultureWorkReopen/NewGardenReopenWorkProgressByContractorCardManage"
import NewCivilReopenWorkProgressCardManage from "./NewDashBoardComponents/CivilWorkReopen/NewCivilReopenWorkProgressCardManage"
import NewElectricalReopenWorkProgressCardManage from "./NewDashBoardComponents/ElectricalWorkReopen/NewElectricalReopenWorkProgressCardManage"
import HorticultureWorKAssignedCardManage from "./NewDashBoardComponents/HorticultureWorkCard/HorticultureWorkAssignedByOfficialsCardManage"
import CivilWorkAssignByOfficialsCardManage from "./NewDashBoardComponents/CivilWorkAssignedByOfficials/CivilWorkAssignedByOfficialsCardManage"
import ElectricalWorkAssignedByOficialsCardManage from "./NewDashBoardComponents/ElectricalWorkAssignedByOfficials/ElectricalWorkAssignedByOfficialsCardsManage"
import { useTranslation } from "react-i18next"
import SportsWork from "./NewDashBoardComponents/SportsWork/SportsWork"
import SportsWorkProgressByContractorCardManage from "./NewDashBoardComponents/SportWorkProgressByContractor/SportWorkProgressByContractorCardManage"
import SportsWorkReopenWorkProgressCardManage from "./NewDashBoardComponents/SportsWorkReopen/SportsWorkReopenByContractorCardManage"
import SecurityWorkCards from "./NewDashBoardComponents/SecurityWork/SecurityWorkCardManage"
import { EmployeeAndContractorWisePendingWorkCard } from "./GarderDashboardComponent/EmployeeAndContractorWiseWorkPending/EmployeeAndContractorWiseWorkPendingCard"
import { DailyPieChartDataAPI } from "../../../Redux/MainCountSlice/NewDashboardMainCount"
import { GardenDailyWork } from "./GardenDailyWork"
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import GardenDailyTaskBar from "./NewDashBoardComponents/GardenDailyTaskBar/Bar/GardenDailyTaskBar"
import moment from "moment";
import { GardenWorkDailyChecklist } from "./NewDashBoardComponents/GardenWorkDailyChecklist/GardenWorkDailyChecklist"
import { RoadWorkDailyChecklist } from "./NewDashBoardComponents/GardenWorkDailyChecklist/RoadWorkDailyChecklist"
import { WorkTypeButtonGetAPI } from "../../../Redux/MainCountSlice/MainWorkTypeButtonSlice"

export const OfficialDashboard = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let DDLApiFlag = searchParams.get("DDLApiFlag")


    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Countdata, setCountdata] = useState({})
    const [NewCountdata, setNewCountdata] = useState({})

    // const [FinancialYearID, setFinancialYear] = useState('')
    const [HeaderFinancialYear, setHeaderFinancialYear] = useState('')
    const [HeaderMonthID, setHeaderMonthID] = useState('')
    const [MonthID, setMonthID] = useState('')
    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)
    const [ZoneValue, setZoneValue] = useState(null)
    const [ZoneLabel, setZoneLabel] = useState(null)
    // 'moment().format("YYYY-MM-DD")'
    const [Date, setDate] = useState('');
    const [DailyPieData, setDailyPieData] = useState([])

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "",
    })

    const [ActiveButton, setActiveButton] = useState('उद्यान दैनंदिन कामे');

    const handleButtonClick = (index) => {
        setActiveButton(index);
    };

    const handleGetFinancialYearID = (FinancialYear, Month) => {
        setHeaderMonthID(Month)
        setHeaderFinancialYear(FinancialYear)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardName.ID,
            M_ZoneID: ZoneValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "WEB",
            token: token,
            handleGetCount: handleGetCount
        }
        // if (YearValue && ZoneValue) {
        dispatch(GardenDashMainCountDataAPI({ data }))
        // }
        // console.log(data)
    }, [YearValue, MonthValue, WardName.ID, ZoneValue])

    const { GardenDashCountData, isLoading } = useSelector(state => state.GardenDashMainCountData)

    const handleGetCount = (data) => {
        // console.log("data", data)
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }

    const handleDashboardGetCount = (data) => {
        // console.log("data", data)
        let tempData = {}
        data.forEach((item) => {
            tempData[item.assignTotalCount] = item.totalCount
        })
        setNewCountdata(tempData)
    }

    // const [HorticultureDDlData, setHorticultureDDlData] = useState()
    // const [CivilDDlData, setCivilDDlData] = useState()
    // const [ElectricalDDlData, setElectricalDDlData] = useState()
    // const [SportDDlData, setSportDDlData] = useState()

    // useEffect(() => {
    //     HeaderDDL('Horticulture')
    //     HeaderDDL('Civil')
    //     HeaderDDL('Electrical')
    //     HeaderDDL('Sport')
    // }, [YearValue, MonthValue, WardName.ID, Language.Label])

    // const HeaderDDL = (Flag) => {
    //     const data = {
    //         M_FinancialYearID: YearValue,
    //         M_MonthID: MonthValue,
    //         M_WardID: WardName.ID,
    //         M_UserID: UserID,
    //         Flag: Flag,
    //         token: token,
    //         Language: Language.Label,
    //         setHorticultureDDlData: setHorticultureDDlData,
    //         setCivilDDlData: setCivilDDlData,
    //         setElectricalDDlData: setElectricalDDlData,
    //         setSportDDlData: setSportDDlData,
    //     }
    //     if (YearValue && MonthValue && WardName.ID) {
    //         dispatch(CommContractorDDLAPI({ data }))
    //     }
    // }
    // const { DDLData, isDDLLoading } = useSelector(state => state.CommContractorDDLData)

    // console.log("dsuhidf",Language)

    // useEffect(() => {
    //     const data = {
    //         // M_FinancialYearID: 2023,
    //         // M_MonthID: 1,
    //         // M_WardID: WardValue,
    //         M_UserID: UserID,
    //         Flag: 'ALL',
    //         token: token,
    //     }
    //     // if (YearValue && MonthValue && WardValue) {
    //         dispatch(WorkAssignByOfficialsCommCountDDLAPI({ data }))
    //     // }
    // }, [])

    // const { WorkAssignDDLData, isWorkAssignDDLLoading } = useSelector(state => state.WorkAssignByOfficialsCommCountDDL)

    // console.log('gyuguy',DDLData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardName.ID,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "Web",
            M_GD_WorkPeriodID: '0',
            token: token,
            setDailyPieData: setDailyPieData,
            M_ZoneID: ZoneValue,
            Language: Language.Label,
        }

        // if (YearValue && Language.Label && ZoneValue) {
        //     { Language.Label != '' && dispatch(DailyPieChartDataAPI({ data })) }
        // }
    }, [YearValue, MonthValue, Language.Label, WardName.ID, ZoneValue])

    const { isPieLoading } = useSelector(state => state.DailyPieChartData)

    const Receiveddata = {
        // labels: ["प्रलंबित कामे", "पूर्ण झालेली कामे"],
        labels: DailyPieData.map((i) => i.paramName),
        datasets: [
            {
                label: '# of Votes',
                data: DailyPieData.map((i) => i.totalCount),
                backgroundColor: [
                    "#ffc33d",
                    "#f48242"
                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };

    const [WorkTypeBtnData, setWorkTypeBtnData] = useState([])
    const [WorkActiveButton, setWorkActiveButton] = useState(0);

    // ----------------WorkType Button API -----------

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            Flag: "DB_Master",
            token: token,
            Language: Language.Label,
            handleWorkButtonData: handleWorkButtonData
        }

        { Language.Label != '' && dispatch(WorkTypeButtonGetAPI({ data })) }

    }, [Language.Label, ActiveButton])

    const { isDDLLoading } = useSelector(state => state.WorkTypeButtonGetData)

    const handleWorkButtonData = (data) => {
        setWorkTypeBtnData && setWorkTypeBtnData(data)
        setWorkActiveButton && setWorkActiveButton(data && data.length > 0 && data[0]?.workTypeID)
    }

    const handleWorkActiveButtonClick = (WorkTypeID) => {
        // console.log("WorkTypeID", WorkTypeID)
        setWorkActiveButton(WorkTypeID);
    };

    return (
        <>
            {(isLoading || isDDLLoading) && <Loading />}
            {/* <div className="main-content side_shrink position-relative border-radius-lg "></div> */}
            <div>
                <Header
                    // DashboardHeading="Garden Maintenance"
                    setYearValue={setYearValue}
                    setMonthValue={setMonthValue}
                    FinancialYear={FinancialYear}
                    setFinancialYear={setFinancialYear}
                    Month={Month}
                    setMonth={setMonth}
                    setYearLabel={setYearLabel}
                    setMonthLabel={setMonthLabel}
                    setWardValue={setWardValue}
                    setWardLabel={setWardLabel}
                    DashboardName={DashboardName}
                    HideYearMonthDDL='Dashboard'
                    Language={Language}
                    setLanguage={setLanguage}

                />
                {/* <Sidebar active="dashboard" /> */}
                <div className="wrapper bg-change">
                    <Sidebar
                        ProjectTypeId={ProjectTypeId}
                        Language={Language}
                    />
                    <div className="content1 content mt-2" style={{ height: '100vh' }}>
                        <div className="py-2 master_border_master_card ml-lg-n2 me-lg-2">
                            <CommonDashboardWardNameDDL
                                Countdata={Countdata}
                                setWardValue={setWardValue}
                                setWardLabel={setWardLabel}
                                setZoneValue={setZoneValue}
                                setZoneLabel={setZoneLabel}
                                Language={Language}
                                ZoneNameDDLID={ZoneNameDDLID}
                                setZoneNameDDLID={setZoneNameDDLID}
                                WardName={WardName}
                                setWardName={setWardName}
                            />
                            <GardenMasterCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ZoneValue={ZoneValue}
                                ZoneLabel={ZoneLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                                Language={Language}
                            />
                            <hr className="my-2" />
                            <GardenDailyWork
                                ActiveButton={ActiveButton}
                                setActiveButton={setActiveButton}
                                handleButtonClick={handleButtonClick}
                                setYearValue={setYearValue}
                                setMonthValue={setMonthValue}
                                FinancialYear={FinancialYear}
                                setFinancialYear={setFinancialYear}
                                Month={Month}
                                setMonth={setMonth}
                                setYearLabel={setYearLabel}
                                setMonthLabel={setMonthLabel}
                                setWardValue={setWardValue}
                                setWardLabel={setWardLabel}
                            />
                        </div>


                        <div className="vertical_height_with_scroll m-0 pe-2">
                            {
                                ActiveButton == "उद्यान दैनंदिन कामे" &&
                                <>
                                    <div className="row m-0 pt-1 px-2  mt-2 mb-2 bg-green " >
                                        <div className="col-12 col-lg-3">
                                            <p className="pt-1 fw-bold mb-0 text-white" style={{ fontSize: '19px' }}>{t('Dashboard.Titles.Garden_Daily_Work')} ({t('EmployeeAndContractor.Checklist')})   </p>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="btn-group text-center float-end" role="group" aria-label="Basic outlined example" style={{ border: '1px solid rgb(223, 218, 218)', borderRadius: '5px' }}>
                                                {
                                                    WorkTypeBtnData && WorkTypeBtnData.length > 0 && WorkTypeBtnData.map((i, j) =>
                                                        <>
                                                            <button
                                                                key={i.workTypeID}
                                                                style={{ whiteSpace: 'nowrap' }}
                                                                onClick={() => handleWorkActiveButtonClick(i.workTypeID)}
                                                                className={
                                                                    WorkActiveButton == i?.workTypeID
                                                                        ? "btn fw-bold px-2 activebutton2 text-white d-flex border-ends2"
                                                                        : "text-white fw-bold btn px-2 d-flex border-right "
                                                                }
                                                            >
                                                                {i.workTypeName}
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-1 float-end ">
                                            <label for="exampleInputEmail1"
                                                className="form-label pt-1 float-end fw-bold text-white fs-6"
                                            >Date:</label>
                                        </div>
                                        <div className="col-12 col-lg-2 pb-1">
                                            <input type="date"
                                                class="form-control "
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                name="Date"
                                                value={Date}
                                                onChange={(e) => setDate(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {
                                        RoleID == 2 || RoleID == 3 || RoleID == 7 || RoleID == 10 || RoleID == 15 ?
                                            <div className="me-0 ms-0 mt-1 me-1 pb-0 pe-1 master_border ">
                                                {/* <GardenDailyWorkCardManage
                                            Countdata={Countdata}
                                            YearValue={YearValue}
                                            YearLabel={YearLabel}
                                            MonthValue={MonthValue}
                                            MonthLabel={MonthLabel}
                                            WardValue={WardValue}
                                            WardLabel={WardLabel}
                                            ZoneValue={ZoneValue}
                                            ZoneLabel={ZoneLabel}
                                            ProjectTypeId={ProjectTypeId}
                                            DashboardName={DashboardName}
                                            Language={Language}
                                        /> */}

                                                {/* {
                                            DailyPieData.some(i => i.totalCount !== 0) ? */}


                                                {/* <div className="col-12 col-lg-5 col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <text>No Data Found</text>
                                                </div> */}
                                                {/* } */}
                                                {/* <GardenDailyTaskBar
                                                    YearValue={YearValue}
                                                    YearLabel={YearLabel}
                                                    MonthValue={MonthValue}
                                                    MonthLabel={MonthLabel}
                                                    WardValue={WardValue}
                                                    WardLabel={WardLabel}
                                                    ZoneValue={ZoneValue}
                                                    ZoneLabel={ZoneLabel}
                                                    Language={Language}
                                                    Date={Date} /> */}
                                                <GardenWorkDailyChecklist
                                                    YearValue={YearValue}
                                                    MonthValue={MonthValue}
                                                    WardValue={WardValue}
                                                    ZoneValue={ZoneValue}
                                                    Language={Language}
                                                    AssetType='1'
                                                    Date={Date}
                                                    WorkActiveButton={WorkActiveButton}
                                                    ActiveButton='Daily_Officer'
                                                />
                                            </div>
                                            :
                                            <></>
                                    }


                                    <div className="row m-0 pt-1 px-2  mt-2 mb-2 bg-green " >
                                        <div className="col-12 col-lg-9">
                                            <p className="pt-1 fw-bold mb-0 text-white" style={{ fontSize: '19px' }}>{t('Dashboard.Titles.Road_Divider')} ({t('EmployeeAndContractor.Checklist')}) </p>
                                        </div>
                                        <div className="col-12 col-lg-1 float-end ">
                                            <label for="exampleInputEmail1"
                                                className="form-label pt-1 float-end fw-bold text-white fs-6"
                                            >Date:</label>
                                        </div>
                                        <div className="col-12 col-lg-2 pb-1">
                                            <input type="date"
                                                class="form-control "
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                name="Date"
                                                value={Date}
                                                onChange={(e) => setDate(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {
                                        RoleID == 2 || RoleID == 3 || RoleID == 7 || RoleID == 10 || RoleID == 15 ?
                                            <div className="me-0 ms-0 mt-1 me-1 pb-0 pe-1 master_border ">
                                                <RoadWorkDailyChecklist
                                                    YearValue={YearValue}
                                                    MonthValue={MonthValue}
                                                    WardValue={WardValue}
                                                    ZoneValue={ZoneValue}
                                                    Language={Language}
                                                    AssetType='2'
                                                    Date={Date}
                                                    WorkActiveButton={WorkActiveButton}
                                                    ActiveButton='Daily_Officer'
                                                />
                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                            }

                            {/* <div className="row">
                            <div className="col-12 col-lg-6  pt-2 ps-2 ps-lg-4" style={{ color: "red" }}>
                                <div className="fw-bold" style={{ fontSize: "22px" }}>{t('Dashboard.Titles.Work_Assigned_by_Officials')}</div>
                            </div>
                            <div className="col-12 col-lg-6 pt-2 ps-2 text-center" style={{ color: "red" }}>
                                <div className="fw-bold" style={{ fontSize: "16px" }}>कामाचे दिवस </div>
                            </div>
                        </div> */}
                            {
                                ActiveButton == "प्रशासनाने वाटप केलेली कामे" &&
                                <>
                                    <div className="row m-0 pt-1 px-2  mt-2 mb-2 bg-green " >
                                        <div className="col-12 col-lg-7 py-1">
                                            <span className="fw-bold" style={{ fontSize: "19px" }}>{t('Dashboard.Titles.Work_Assigned_by_Officials')} -  {t('EmployeeAndContractor.Officer_Name_Pending_Work_Status')}</span>

                                        </div>
                                        <div className="col-12 col-lg-5 pb-1">
                                            <div className="btn-group text-center float-end" role="group" aria-label="Basic outlined example" style={{ border: '1px solid rgb(223, 218, 218)', borderRadius: '5px' }}>
                                                {
                                                    WorkTypeBtnData && WorkTypeBtnData.length > 0 && WorkTypeBtnData.map((i, j) =>
                                                        <>
                                                            <button
                                                                key={i.workTypeID}
                                                                style={{ whiteSpace: 'nowrap' }}
                                                                onClick={() => handleWorkActiveButtonClick(i.workTypeID)}
                                                                className={
                                                                    WorkActiveButton == i?.workTypeID
                                                                        ? "btn fw-bold px-2 activebutton2 text-white d-flex border-ends2"
                                                                        : "text-white fw-bold btn px-2 d-flex border-right "
                                                                }
                                                            >
                                                                {i.workTypeName}
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="pt-2 ps-2">
                                        <span className="fw-bold" style={{ fontSize: "19px" }}>{t('Dashboard.Titles.Work_Assigned_by_Officials')} -  {t('EmployeeAndContractor.Officer_Name_Pending_Work_Status')}</span>
                                    </div> */}

                                    <div className="border rounded mt-0  me-0 my-1 rounded bg-white border-0 sec_sec sec_sec_375 px-2 ps-2 pt-0">

                                        {/* <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center m-1">
                                            <HorticultureWorKAssignedCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                Countdata={Countdata}
                                                HeaderMonthID={HeaderMonthID}
                                                NewCountdata={NewCountdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                Language={Language}
                                                setLanguage={setLanguage}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                            />
                                        </div>

                                        <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">
                                            <CivilWorkAssignByOfficialsCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                            />

                                        </div>

                                        <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                            <ElectricalWorkAssignedByOficialsCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                            />
                                        </div>
                                        <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                            <SportsWork
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                            />
                                        </div>


                                        <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                            <SecurityWorkCards
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                            />

                                        </div> */}
                                        {
                                            RoleID == 2 || RoleID == 3 || RoleID == 15 ?

                                                <div className=" mt-2 m-0 p-0 master_border last_row">
                                                    <EmployeeAndContractorWisePendingWorkCard
                                                        ActiveButton='कर्मचारी'
                                                        ZoneValue={ZoneValue}
                                                        WardValue={WardValue}
                                                        Language={Language}
                                                        WorkActiveButton={WorkActiveButton}
                                                    />
                                                </div>
                                                : ''
                                        }

                                    </div>
                                </>
                            }

                            {/* Work Progress Card Start */}

                            {/* Work Progress By Contractor */}

                            {
                                ActiveButton == "ठेकेदारनिहाय कामाची प्रगती" &&
                                <>
                                    <div className="row m-0 pt-1 px-2  mt-2 mb-2 bg-green " >
                                        <div className="col-12 col-lg-7 py-1">
                                            <span className="fw-bold" style={{ fontSize: "19px" }}>{t('Dashboard.Titles.Work_Progress_By_Contractor')} - {t('EmployeeAndContractor.Contractor_Name_Pending_Work_Status')}</span>
                                        </div>
                                        <div className="col-12 col-lg-5 pb-1">
                                            <div className="btn-group text-center float-end" role="group" aria-label="Basic outlined example" style={{ border: '1px solid rgb(223, 218, 218)', borderRadius: '5px' }}>
                                                {
                                                    WorkTypeBtnData && WorkTypeBtnData.length > 0 && WorkTypeBtnData.map((i, j) =>
                                                        <>
                                                            <button
                                                                key={i.workTypeID}
                                                                style={{ whiteSpace: 'nowrap' }}
                                                                onClick={() => handleWorkActiveButtonClick(i.workTypeID)}
                                                                className={
                                                                    WorkActiveButton == i?.workTypeID
                                                                        ? "btn fw-bold px-2 activebutton2 text-white d-flex border-ends2"
                                                                        : "text-white fw-bold btn px-2 d-flex border-right "
                                                                }
                                                            >
                                                                {i.workTypeName}
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="border m-0 my-1 rounded border-0 p-2 sec_sec bg-white">
                                        {/* <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center m-1" >
                                            <NewGardenWorkProgressByContractorCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                // DDLData={HorticultureDDlData}
                                                Language={Language}
                                                // HorticultureDDlData={HorticultureDDlData}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}

                                            />

                                        </div>

                                        <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center mx-auto ml-lg-1 mt-4">
                                            <NewCivilWorkProgressByContractorCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                // DDLData={CivilDDlData}
                                                Language={Language}
                                                // CivilDDlData={CivilDDlData}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}
                                            />


                                        </div>

                                        <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                            <NewElectricalWorkProgressByContractorCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                // DDLData={ElectricalDDlData}
                                                Language={Language}
                                                // ElectricalDDlData={ElectricalDDlData}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}
                                            />


                                        </div>

                                        <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                            <SportsWorkProgressByContractorCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                // DDLData={SportDDlData}
                                                Language={Language}
                                                // SportDDlData={SportDDlData}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}
                                            />
                                        </div> */}
                                        {
                                            RoleID == 2 || RoleID == 3 || RoleID == 15 ?

                                                <div className=" mt-2 m-0 p-0 master_border last_row">
                                                    <EmployeeAndContractorWisePendingWorkCard
                                                        ActiveButton='ठेकेदार'
                                                        ZoneValue={ZoneValue}
                                                        WardValue={WardValue}
                                                        Language={Language}
                                                        WorkActiveButton={WorkActiveButton}
                                                    />
                                                </div>
                                                : ''
                                        }
                                    </div>

                                </>
                            }
                            {/* Work Progress Card End */}


                            {/* Reopen Work Progress Card Start */}
                            {
                                ActiveButton == "पुन्हा वाटप केलेल्या कामाची प्रगती" &&
                                <>
                                    <div className="row m-0 pt-1 px-2  mt-2 mb-2 bg-green " >
                                        <div className="col-12 col-lg-7 py-1">
                                            <span className="fw-bold" style={{ fontSize: "19px" }}>{t('Dashboard.Titles.Work_Reopen_Progress_By_Contractor')} - {t('EmployeeAndContractor.Contractor_Name_Pending_Work_Status')}</span>

                                        </div>
                                        <div className="col-12 col-lg-5 pb-1">
                                            <div className="btn-group text-center float-end" role="group" aria-label="Basic outlined example" style={{ border: '1px solid rgb(223, 218, 218)', borderRadius: '5px' }}>
                                                {
                                                    WorkTypeBtnData && WorkTypeBtnData.length > 0 && WorkTypeBtnData.map((i, j) =>
                                                        <>
                                                            <button
                                                                key={i.workTypeID}
                                                                style={{ whiteSpace: 'nowrap' }}
                                                                onClick={() => handleWorkActiveButtonClick(i.workTypeID)}
                                                                className={
                                                                    WorkActiveButton == i?.workTypeID
                                                                        ? "btn fw-bold px-2 activebutton2 text-white d-flex border-ends2"
                                                                        : "text-white fw-bold btn px-2 d-flex border-right "
                                                                }
                                                            >
                                                                {i.workTypeName}
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>


                                    <div className="border m-0 rounded bg-white border-0 p-2 pb-2 sec_sec">

                                        {/* <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center m-1">
                                            <NewGardenReopenWorkProgressCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                // Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                // DDLData={HorticultureDDlData}?
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}
                                            />


                                        </div>

                                        <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center mx-auto ml-lg-1 mt-4">
                                            <NewCivilReopenWorkProgressCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                // DDLData={CivilDDlData}
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}
                                            />

                                        </div>

                                        <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                            <NewElectricalReopenWorkProgressCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                // DDLData={ElectricalDDlData}
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}
                                            />                           
                                        </div>

                                        <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                            <SportsWorkReopenWorkProgressCardManage
                                                HeaderFinancialYear={HeaderFinancialYear}
                                                HeaderMonthID={HeaderMonthID}
                                                Countdata={Countdata}
                                                handleGetFinancialYearID={handleGetFinancialYearID}
                                                YearValue={YearValue}
                                                YearLabel={YearLabel}
                                                MonthValue={MonthValue}
                                                MonthLabel={MonthLabel}
                                                WardValue={WardValue}
                                                WardLabel={WardLabel}
                                                ZoneValue={ZoneValue}
                                                ZoneLabel={ZoneLabel}
                                                ProjectTypeId={ProjectTypeId}
                                                DashboardName={DashboardName}
                                                Language={Language}
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                ZoneNameDDLID={ZoneNameDDLID}
                                                setZoneNameDDLID={setZoneNameDDLID}
                                            />
                                        </div> */}
                                        {
                                            RoleID == 2 || RoleID == 3 || RoleID == 15 ?

                                                <div className=" mt-2 m-0 p-0 master_border last_row">
                                                    <EmployeeAndContractorWisePendingWorkCard
                                                        ActiveButton='ReopenContractor'
                                                        ZoneValue={ZoneValue}
                                                        WardValue={WardValue}
                                                        Language={Language}
                                                        WorkActiveButton={WorkActiveButton}
                                                    />
                                                </div>
                                                : ''
                                        }
                                    </div>

                                </>
                            }
                            {/* {
                                RoleID == 2 || RoleID == 3 || RoleID == 15 ?

                                    <div className=" mt-4 m-0 p-0 master_border last_row">
                                         <EmployeeAndContractorWisePendingWorkCard /> 
                                    </div>
                                    : ''
                            } */}
                        </div>


                        {/* REopen Work Progress Card End */}

                    </div>
                    {/* <!-- [ Main Content ] end --> */}
                </div>
            </div>


        </>

    )
}