import React from "react";
import { useTranslation } from "react-i18next";
const Image_1 = "../assets/img/Loading.png";
const BrokenImage = '../assets/img/not_found.png'

export default function AfterWorkPhotoModal({ setshowWorkImagePopup, AfterWorkPhotoData, workPhotoData, setipost, ispost, Heading }) {

     const { t } = useTranslation()

    const imageOnLoad = (event) => {
        console.log(event)
        console.log(`Picture successfully ${event.currentTarget.src} loaded.`);
        if (event.currentTarget.className !== "error") {
            event.currentTarget.className = "success";
        }
    };
    const imageOnError = (event) => {
        event.currentTarget.src = BrokenImage;
        event.currentTarget.className = "error";
    };

    return (
        <>
            <div className=" modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered mt-5" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className=" ml-4">
                                <span style={{ fontSize: '19px', fontWeight: 'bold' }}>Photo</span>
                            </div>

                            <button type="button" className="btn-close mr-3 mt-1" data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={() => {
                                    setshowWorkImagePopup(false)
                                }}>
                                {/* <span className="x" aria-hidden="true">&times;</span> */}
                            </button>
                        </div>
                        <div className="row modal-body" >
                            <div className="col-6 modal-header justify-content-center" >
                                {/* <img src={ImageNotFound} /> */}
                                {/* {
                                    AfterWorkPhotoData.image === null ?
                                    // <img src={ImageNotFound} />
                                    <h1>hello</h1>
                                    :
                                    <h1>fj</h1>
                                } */}
                                {/* {
                                    AfterWorkPhotoData ?
                                        <img className="col-12" src={AfterWorkPhotoData == null ? "ImageNotFound" : AfterWorkPhotoData} style={{ width: "400px", height: "400px" }} />
                                        : <h4>No Image found</h4>
                                } */}
                                <div className="text-center fs-6 fw-bold text-danger">{t('AdminDashboard.Admin_Sidebar_Table_Heading.Before_Work_Photo')}</div>
                                {
                                    workPhotoData ?
                                        <img className="col-12" style={{ width: "400px", height: "400px" }} src={workPhotoData == null ? "ImageNotFound" : workPhotoData}
                                            onLoad={imageOnLoad} onError={imageOnError} />
                                        : <img src="../assets/img/not_found.png" />
                                }
                                {/* {

                                        AfterWorkPhotoData ?
                                            <img className="col-12"
                                                src={`data:image/png;base64,${AfterWorkPhotoData}`}
                                                style={{ width: "400px", height: "400px" }}
                                            />

                                            : 'No Image found'
                                } */}


                            </div>
                            <div className="col-6 modal-header justify-content-center" >
                            <div className="text-center fs-6 fw-bold text-danger">{t('AdminDashboard.Admin_Sidebar_Table_Heading.After_Work_Photo')}</div>
                                {
                                    AfterWorkPhotoData ?
                                        <img className="col-12" style={{ width: "400px", height: "400px" }} src={AfterWorkPhotoData == null ? "ImageNotFound" : AfterWorkPhotoData}
                                            onLoad={imageOnLoad} onError={imageOnError} />
                                        : <img src="../assets/img/not_found.png" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


