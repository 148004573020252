import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../../../Helper/Context";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI, NewScreenDailyPreventiveWorkCountPopupDataAPI } from "../../../../../../Redux/GardenDashboardSlice/DailyPreventiveWorkSlice";
import { Loading } from "../../../../../../Helper/Loading";
import ViewURL from "../../../../../../Components/ViewURL/ViewURL";

const CompletechecklistPopUp = (props) => {

    const { APIFlag, cancelbtn, YearValue, MonthValue, AssetType, confirmBtnCallBack, deleteRecord, message, heading, Data, Heading } = props

    // console.log('APIFlag' ,APIFlag)

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const [ImagePopUpData, setImagePopUpData] = useState('')
    const [showImagePopup, setshowImagePopup] = useState(false)
    const [ImageHeading, setImageHeading] = useState('')

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setImageHeading(heading)
    }

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            M_AssetID: Data?.m_AssetID,
            M_AssetTypeID: AssetType,
            T_KPIUploadDailyWorkID: Data?.t_kpiUploadDailyWorkID,
            M_WorkTypeID: 0,
            M_UserID: UserID,
            From: '1',
            To: '99999',
            token: token,
            Language: Language.Label,
            Flag: APIFlag
        }
        { Language.Label != '' && dispatch(ChecklistProgressWiseEmplDailyPendingCompletedSelectDataAPI({ data })) }
    }, [Language.Label])

    const { tableData, isLoading } = useSelector(state => state.ChecklistProgressWiseEmplDailyPendingCompletedSelectData)

    return (
        <>
            {/* <div className="modal-dialog dialog_width modal-lg modal-dialog-scrollable mt-lg-5 pt-lg-5" role="document" > */}

            <div className="modal fade show" style={{ display: "flex", alignItems: 'center', backgroundColor: "#00000090" }}>
                <div className="modal-dialog dialog_width modal-lg mt-lg-5 pt-lg-5" role="document" >
                    <div className="modal-content p-3">
                        <div className="modal-header py-0">

                            <h5 className="card-title fw-bold" style={{ paddingLeft: '10px' }} id="exampleModalLongTitle">{heading}</h5>

                            <button onClick={() => cancelbtn()} type="button" className="close" style={{ marginLeft: "auto" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {isLoading && <Loading />}
                        <div className="modal-body mx-0">
                            <div className="row">
                                <div className="table-responsive " style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                    <table className="table table-striped ">
                                        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                            <tr className="">
                                                <th className="w-6 px-3">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                <th> {t('EmployeeAndContractor.ChecklistName')}</th>
                                                <th style={{ width: '7%' }}>{t('EmployeeAndContractor.Count')}</th>
                                                <th style={{ width: '5%' }}>{t('EmployeeAndContractor.Yes')}</th>
                                                <th style={{ width: '5%' }}>{t('EmployeeAndContractor.No')}</th>
                                                <th style={{ width: '12%' }}>{t('EmployeeAndContractor.ChecklistPhoto')}</th>
                                                <th>{t('AdminDashboard.Admin_Titles.Remark')}</th>
                                            </tr>

                                        </thead>
                                        {
                                            tableData && tableData.length > 0 ? tableData.map((i, j) =>
                                                <tr>
                                                    <td className="text-center">{j + 1}</td>
                                                    <td>{i.m_Daily_Work_CheckList ? i.m_Daily_Work_CheckList : '-'}</td>
                                                    <td className='text-center'>{i?.checkListCount ? i.checkListCount : '0'}</td>
                                                    <td className='fw-bold text-center' style={{ color: 'darkgreen' }}>{i?.m_Daily_Work_CheckList_YesID == 1 ? 'Yes' : '-'}</td>
                                                    <td className='fw-bold text-center' style={{ color: 'orangered' }}>{i?.m_Daily_Work_CheckList_NoID == 1 ? 'No' : '-'}</td>
                                                    <td className='text-center'>
                                                        {
                                                            (i.checkListPhoto == null) ?
                                                                <span>-</span>
                                                                :
                                                                <>
                                                                    <i className="fa-solid fa-eye" title='View'
                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                        onClick={() => (
                                                                            handleShowImage(i.checkListPhoto),
                                                                            setImageHeading(t('EmployeeAndContractor.ChecklistPhoto'))
                                                                        )}
                                                                    ></i>
                                                                </>
                                                        }
                                                    </td>
                                                    <td>{i.remark ? i.remark : '-'}</td>
                                                </tr>
                                            ) :
                                                <td colSpan={15}>No Found data</td>
                                        }


                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {
                showImagePopup ?
                    <ViewURL
                        Heading={ImageHeading}
                        setshowImagePopup={setshowImagePopup}
                        ImagePopUpData={ImagePopUpData}

                    />
                    : ''


            }
        </>);
}

export default CompletechecklistPopUp;
