
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { WorkTypeDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const WorkTypeDDL = (props) => {
    const { WorkType, setWorkType, rowData, Flag, IsPost, IsDisabled, Language, Flag1 } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token, Language: Language.Label }
        { Language.Label != '' && dispatch(WorkTypeDDLAPI({ data })) }
    }, [Language.Label, IsPost])

    const { WorkTypeData } = useSelector(state => state.WorkTypeDDLData)

    // console.log("dsyfgyufgsuddsfd", Language)


    useEffect(() => {
        handleWorkTypeDDL()
    }, [WorkTypeData])

    const handleWorkTypeDDL = () => {
        // console.log(DeptDDLDataa)
        if (WorkTypeData && WorkTypeData.table && WorkTypeData.table.length > 0) {
            let list = WorkTypeData.table.map((item, index) => ({
                value: item.workTypeID,
                label: item.workTypeName,
            }))

            setWorkType({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_WorkTypeID : 0 && Flag1 === 'Sport' ? list[3].value : '0' && Flag1 === 'Horticulture' ? list[0].value : '0'
                    && Flag1 === 'Civil' ? list[1].value : '0' && Flag1 === 'Electrical' ? list[2].value : '0' && Flag1 === 'Sport' ? list[3].value : '0' && Flag1 === 'SecurityWork' ? list[4].value : '0',
                Label: Flag === 'Update' ? rowData.workTypeName : "Select..." && Flag1 === 'Sport' ? list[3].label : 'Select...' && Flag1 === 'Horticulture' ? list[0].label : '0'
                    && Flag1 === 'Civil' ? list[1].label : 'Select ...' && Flag1 === 'Electrical' ? list[2].label : 'Select ...' && Flag1 === 'Sport' ? list[3].label : 'Select ...' && Flag1 === 'SecurityWork' ? list[4].label : 'Select ...',
            })
        }
        else {
            setWorkType({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofWorkType"><b>{Flag1 == 'ProjectType' ? 'Project Type' : t('Sidebar.Work_Type')} {Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                isDisabled={Flag === 'Update' && IsDisabled ? true : false}
                className=""
                isSearchable
                maxMenuHeight={150}
                value={{ value: WorkType.ID, label: WorkType.Label }}
                onChange={(e) => {
                    e ?
                        setWorkType({ ...WorkType, ID: e.value, Label: e.label })
                        :
                        setWorkType({ ...WorkType, ID: 0, Label: "Select..." })
                }}
                options={WorkType.DDL}
            />
        </div>
    )
}