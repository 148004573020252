import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GardenDailyWorkCard from './GardenDailyWork'
import { t } from "i18next";

export default function GardenDailyWorkCardManage(props) {

    const { Countdata, HeaderMonthID, Language, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ZoneLabel, ZoneValue, ProjectTypeId, DashboardName } = props

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    const navigate = useNavigate()

    const DailyPreventiveWorkNavigate = (name, ApiFlag, screenFlag) => {
        navigate(`/gardenworkpending?name=${name}&ApiFlag=${ApiFlag}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)
    }

    return (
        <div className="col-12 col-lg-6 ps-4 ms-0">
            <div className="row " style={{ height: '100%' }}>
                <div className="col-12">
                    <h5 className="ps-2 pt-3">{t('EmployeeAndContractor.Daily_Work')}</h5>
                </div>
                <div className="col-12 col-lg-6 mb-1" >
                    <GardenDailyWorkCard
                        title={t('Dashboard.Titles.Pending')}
                        ApiFlag="Pending"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/pending.png'
                        count={Countdata.KPIUploadDailyWork_Pending_TotalCount ? Countdata.KPIUploadDailyWork_Pending_TotalCount : '0'}
                        // navLink='/gardenworkpending'
                        DailyPreventiveWorkNavigate={DailyPreventiveWorkNavigate}
                    />
                </div>
                <div className="col-12 col-lg-6 mb-1">
                    <GardenDailyWorkCard
                        title={t('Dashboard.Titles.Completed')}
                        ApiFlag="Completed"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/completed1.png'
                        count={Countdata.KPIUploadDailyWork_Completed_TotalCount ? Countdata.KPIUploadDailyWork_Completed_TotalCount : '0'}
                        // navLink='/gardenworkcomplete'
                        DailyPreventiveWorkNavigate={DailyPreventiveWorkNavigate}
                    />
                </div>
            </div>
        </div>

    )

}

