
// import { AssetDDLReducer, AssetTypeDDLReducer, DepartmentDDLReducer, DesignationDDLReducer, FinancialYearDDLReducer, MonthDDLReducer, SectorDDLReducer, WardNameDDLReducer, YesNoDDLReducer } from './DDLSlice'
// import { AssetDDLReducer, AssetTypeDDLReducer, ContractorNameDDLReducer, DepartmentDDLReducer, DesignationDDLReducer, EmployeeDDLReducer, FinancialYearDDLReducer, MonthDDLReducer, ProjectNameDDLReducer, SectorDDLReducer, WardNameDDLReducer, WorkTypeDDLReducer, YesNoDDLReducer, ZoneDDLReducer } from './DDLSlice'
// import { AssetDDLReducer, AssetTypeDDLReducer, ContractorNameDDLReducer, DepartmentDDLReducer, DesignationDDLReducer, EmployeeDDLReducer, FinancialYearDDLReducer, MonthDDLReducer, SectorDDLReducer, WardNameDDLReducer, WorkTypeDDLReducer, YesNoDDLReducer, ZoneDDLReducer } from './DDLSlice'
import { AssetDDLReducer, AssetNameDDLDataReducer, AssetTypeDDLReducer, AssetWiseEquipmentNameDDLReducer, CommContractorDDLReducer, CommNewDepartmentDDLReducer, ContactPersonDDLReducer, ContractorNameDDLNewReducer, ContractorNameDDLReducer, ContractorNameReportDDLReducer, ContractorWiseAssetDDLDataReducer, ContractorWiseWardSelectDDLReducer, DailyWorkSheetDesignationDDLReducer, DepartmentDDLReducer, DepartmentWiseDesignationDDLReducer, DepartmentWiseEmployeeDDLReducer, DesignationDDLReducer, EcoliDDLReducer, EmployeeDDLReducer, EquipmentNameDDLReducer, FinancialYearDDLReducer, GDProjectNameDDLReducer, JunctionNameDDLReducer, LocationNameDDLReducer, MaintainByDDLDataReducer, MonthDDLReducer, NewDepartmentDDLReducer, NewRoleDDLReducer, PreventiveWorkAssetNameDDLDataReducer, ProjectNameDDLReducer, ProjectTypeNameDDLReducer, PumpingStationReducer, RoadWorkNameDDLReducer, RoleNameDDLReducer, SectorDDLReducer, ServiceTypeDDLReducer, SeverageLocationDDLReducer, SeverageWorkTypeReducer, StatusDDLReducer, UpdateWorkNameDDLReducer, WardNameDDLReducer, WardWiseAssetNameDDLReducer, WaterSupplyLocationDDLReducer, WaterSupplyPumpingStationDDLReducer, WaterSupplyUserDDLReducer, WaterSupplyUserRoleDDLReducer, WorkAssignByDDLReducer, WorkAssignByOfficialsCommCountDDLReducer, WorkNameDDLReducer, WorkPeriodDDLReducer, WorkSubTypeDDLReducer, WorkTypeDDLReducer, WorkTypeWiseContractorDDLReducer, YesNoDDLReducer, ZoneDDLReducer, ZoneNameDDLReducer } from './DDLSlice'
import { ChecklistProgressWiseEmplDailyPendingCompletedSelectDataReducer, ChecklistProgressWiseEmployeeSelectDataReducer, DailyPreventiveWorkTableDataReducer, GetChecklistProgressSelectDataReducer, NewScreenDailyPreventiveWorkCountPopupDataReducer, NewScreenDailyPreventiveWorkTableDataReducer } from './GardenDashboardSlice/DailyPreventiveWorkSlice'
import { ReopenWorkProgressTableDataReducer } from './GardenDashboardSlice/ReopenWorkProgressSlice'
import { WorkAssignedTableDataReducer } from './GardenDashboardSlice/WorkAssignedSlice'
// import { DailyPreventiveWorkTableDataReducer } from './DashboardSlice/DailyPreventiveWorkSlice'
// import { ReopenWorkProgressTableDataReducer } from './DashboardSlice/ReopenWorkProgressSlice'
// import { WorkAssignedTableDataReducer } from './DashboardSlice/WorkAssignedSlice'
import { GardenDashMainCountDataReducer } from './MainCountSlice/GardenMainCount'
import { GardenMasterDeleteReducer, GardenMasterGeoLocationDataReducer, GardenMasterPostDataReducer, GardenMasterTableDataReducer, GardenMasterTableExportDataReducer } from './MasterSlice/GardenMasterSlice'
import { LocationNameMasterDeleteReducer, LocationNameMasterPostDataReducer, LocationNameMasterTableDataReducer } from './MasterSlice/LocationNameMaster'
import { WorkProgressTableDataReducer } from './RoadDashboardSlice/WorkProgressSlice'
import { WorkApprovalTableDataReducer } from './RoadDashboardSlice/WorkApprovalSlice'
import { WorkUploadTableDataReducer } from './RoadDashboardSlice/WorkUploadSlice'
import { ContractorNameDeleteReducer, ContractorNamePostDataReducer, ContractorNameTableDataReducer } from './SidebarMasterSlice/ContractorNameSlice'
import { DepDeleteReducer, DepartmentDeleteReducer, DepartmentTableDataReducer } from './SidebarMasterSlice/DepartmentSlice'
import { DesignationDeleteReducer, DesignationPostDataReducer, DesignationTableDataReducer } from './SidebarMasterSlice/DesignationSlice'
import { EmployeeDeleteReducer, EmployeePostDataReducer, EmployeeTableDataReducer } from './SidebarMasterSlice/EmployeeSlice'
import { ProjectDeleteReducer, ProjectPostDataReducer, ProjectTableDataReducer } from './SidebarMasterSlice/ProjectNameSlice'
import { ProjectWiseContractorDeleteReducer, ProjectWiseContractorPostDataReducer, ProjectWiseContractorTableDataReducer } from './SidebarMasterSlice/ProjectWiseContractorSlice'
import { ProjectWiseWorkNameDeleteReducer, ProjectWiseWorkNamePostDataReducer, ProjectWiseWorkNameTableDataReducer } from './SidebarMasterSlice/ProjectWiseWorkName'
import { SectorDeleteReducer, SectorPostDataReducer, SectorTableDataReducer } from './SidebarMasterSlice/SectorMasterSlice'
import { WardDeleteReducer, WardPostDataReducer, WardTableDataReducer } from './SidebarMasterSlice/WardSlice'
import { WorkMasterExportTableDataReducer, WorkMasterTableDataReducer } from './SidebarMasterSlice/WorkMasterSlice'
import { WorkSubTypeDeleteReducer, WorkSubTypePostDataReducer, WorkSubTypeTableDataReducer, } from './SidebarMasterSlice/WorkSubTypeSlice'
import { WorkTypeDeleteReducer, WorkTypePostDataReducer, WorkTypeTableDataReducer } from './SidebarMasterSlice/WorkTypeSlice'
import { ZoneMasterDeleteReducer, ZoneMasterPostDataReducer, ZoneMasterTableDataReducer } from './SidebarMasterSlice/ZoneMasterSlice'
import { WorkNameMasterDeleteReducer, WorkNameMasterPostDataReducer, WorkNameTableDataReducer } from './RoadDashboardSlice/WorkNameSlice'
import { WardWiseWorkTableDataReducer } from './RoadDashboardSlice/WardWiseWorkSlice'
import { UserCreationDeleteReducer, UserCreationExportTableDataReducer, UserCreationPostDataReducer, UserCreationTableDataReducer } from './SidebarMasterSlice/UserCreationSlice'
import { RoadDashMainCountDataReducer } from './MainCountSlice/RoadMaintenceMainCount'
import { GetMapViewDataReducer } from './RoadDashboardSlice/MapViewSlice'
import { LocationMasterDeleteReducer, LocationMasterPostDataReducer, LocationMasterTableDataReducer } from './SidebarMasterSlice/LocationMasterSlice'
import { PumpingStationDeleteReducer, PumpingStationPostDataReducer, PumpingStationTableDataReducer } from './SidebarMasterSlice/PumpingStationSlice'
import { AreaMasterDeleteReducer, AreaMasterPostDataReducer, AreaMasterTableDataReducer } from './SidebarMasterSlice/AreaMasterSlice'
import { SeverageWorkTypeDeleteReducer, SeverageWorkTypePostDataReducer, SeverageWorkTypeTableDataReducer } from './SeverageDashboardSlice/SeverageWorkTypeMasterSlice'
import { SeverageWorkNameDeleteReducer, SeverageWorkNamePostDataReducer, SeverageWorkNameTableDataReducer } from './SeverageDashboardSlice/SeverageWorkNameMasterSlice'
import { PumpingStationMasterDeleteReducer, PumpingStationMasterPostDataReducer, PumpingStationMasterTableDataReducer } from './SeverageDashboardSlice/SeveragePumpingStationMasterSlice'
import { PumpingOperationDeleteReducer, PumpingOperationTableDataReducer } from './SeverageDashboardSlice/SewerageDailyWorkMasterSlice/PumpingOperationSlice'
import { DailyWorkSecurityDeleteReducer, DailyWorkSecurityTableDataReducer } from './SeverageDashboardSlice/SewerageDailyWorkMasterSlice/DailyWorkSecuritySlice'
import { DailyWorkEnergyAuditDeleteReducer, EnergyAuditTableDataReducer } from './SeverageDashboardSlice/SewerageDailyWorkMasterSlice/DailyWorkEnergyAuditSlice'
import { DailyWorkHouseKeepingDeleteReducer, HouseKeepingTableDataReducer } from './SeverageDashboardSlice/SewerageDailyWorkMasterSlice/DailyWorkHouseKeepingSlice'
import { RoadWorkReportCompleteTableDataReducer } from './ReportsSlice/RoadWorkReportCompleteSlice'
import { PenaltyWorkReportExportTableDataReducer, PenaltyWorkReportPDFTableDataReducer, PenaltyWorkReportTableDataReducer, PreventiveWorkReportExportTableDataReducer, PreventiveWorkReportTableDataReducer } from './ReportsSlice/PreventiveWorkReportSlice'
import { SewerageDashMainCountDataReducer } from './MainCountSlice/SewerageCount'
import { ServicesMasterDeleteReducer, ServicesTableDataReducer } from './SeverageDashboardSlice/ServicesMasterSlice'
// import { PreventiveWorkReportTableDataReducer } from './ReportsSlice/PreventiveWorkReport'
import { SeveragePipelineDetailsMasterDeleteReducer, SeveragePipelineDetailsMasterPostDataReducer, SeveragePipelineDetailsMasterTableDataReducer } from './SeverageDashboardSlice/SeveragePipelineDetailsMaster'
import { AssignMechCivilPendingDeleteReducer, CustomerServiceMasterDeleteReducer, CustomerServiceTableDataReducer } from './SeverageDashboardSlice/AssignedWorkCustomerServicesSlice'
import { WaterSupplyPumpStationMasterDeleteReducer, WaterSupplyPumpStationPostDataReducer, WaterSupplyPumpStationTableDataReducer } from './WaterSupplyDashBoardSlice/WaterSupplyMasterSlice/WaterSupplyPumpStationSlice'
import { WaterSupplyPipelineMasterDeleteReducer, WaterSupplyPipelineTableDataReducer } from './WaterSupplyDashBoardSlice/WaterSupplyMasterSlice/WaterSupplyPipelineSlice'
import { UserWiseWardMappingPostReducer, WaterSupplyUserWiseWardMappingTableDataReducer } from './SidebarMasterSlice/WaterSupplyUserWiseWardMappingSlice'
import { PHDeleteReducer, PHPostDataReducer, PHTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWaterQualitySlice/PHSlice'
import { ChlorineDeleteReducer, ChlorinePostDataReducer, ChlorineTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWaterQualitySlice/ChlorineSlice'
import { TurbidityDeleteReducer, TurbidityPostDataReducer, TurbidityTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWaterQualitySlice/TurbiditySlice'
import { EColiDeleteReducer, EColiPostDataReducer, EColiTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWaterQualitySlice/EColiSlice'
import { WaterPressureDeleteReducer, WaterPressurePostDataReducer, WaterPressureTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/WaterPressureSlice'
import { WaterAuditDeleteReducer, WaterAuditPostDataReducer, WaterAuditTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/WaterAuditSlice'
import { EnergyConsumptionDeleteReducer, EnergyConsumptionPostDataReducer, EnergyConsumptionTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/EnergyConsumptionSlice'
import { WaterFlowDeleteReducer, WaterFlowPostDataReducer, WaterFlowTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/WaterFlowSlice'
import { ChemicalSupplyDeleteReducer, ChemicalSupplyPostDataReducer, ChemicalSupplyTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/ChemicalSupplySlice'
import { SecurityDeleteReducer, SecurityTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/SecuritySlice'
import { HouseKeepingDeleteReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/HouseKeepingSlice'
import { ValveOperationDeleteReducer, ValveOperationTableDataReducer } from './WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/ValveOperationSlice'
import { DailyPieChartDataReducer, NewDashMainCountDataReducer } from './MainCountSlice/NewDashboardMainCount'
import { CommWorkAssignedTableDataAPIReducer } from './CommDashbaord/WorkAssignTableSlice'
import { CommWorkReopenCountDataAPIReducer, CommWorkReopenGetDataAPIReducer } from './CommDashbaord/WorkReopenSlice/WorkReopenSlice'
import { CommWorkProgressCountDataAPIReducer, CommWorkProgressTableDataAPIReducer } from './CommDashbaord/WorkProgressSlice/WorkProgressSlice'
import { HorticultureWorkAssignedTableDataReducer, HorticultureWorkAssignedTableExportDataReducer } from './CommDashbaord/WorkAssignByOfficialsSlice/HorticultureSlice'
import { ContractorWiseAssetPostDataReducer, ContractorWiseAssetTableDataReducer, ContractorWiseAssetTableExportDataReducer } from './GardenDashboardSlice/ContractorWiseAssetSlice.js/ContractorWiseAssetSlice'
import { HorticultureWorkAssignedCountDataReducer } from './GardenDashboardSlice/GardentMasterCountSlice/WorkAssignedByOfficials/HorticultureWorkAssignedSlice'
import { PlayEquipmentMasterDeleteReducer, PlayEquipmentMasterPostDataReducer, PlayEquipmentMasterQrReportDataReducer, PlayEquipmentMasterTableDataReducer, PlayEquipmentMasterTableExportDataReducer } from './MasterSlice/PlayEquipmentSlice'
import { SportsWorkAssignedTableDataReducer } from './CommDashbaord/SportWorkSlice/SportWorkAssignByOfficialsSlice'
import { DailyWorkSheetPDFDataReducer, DailyWorkSheetTableDataReducer, DailyWorkSheetTablePDFDataReducer } from './CommDashbaord/DailyWorkSheet/DailyWorkSheetSlice'
import { SportWorkProgressTableDataAPIReducer } from './CommDashbaord/WorkProgressSlice/SportWorkProgressSlice'
import { SportWorkReopenByContractorTableDataAPIReducer } from './CommDashbaord/SportWorkReopenByContractorSlice/SportWorkReopenByContractorSlice'
import { GardenCompletedWorkPDFReportTableDataReducer, GardenCompletedWorkReportTableDataReducer } from './GardenDashboardSlice/GardenReportSlice/CompletedWorkReportSlice'
import { CommonOfficerWorkStatusCountPopupTableDataReducer, CommonOfficerWorkStatusTableDataReducer } from './CommDashbaord/OfficerWorkStatus/CommonOfficerWorkStatusSlice'
import { GardenDailyWorkBarChartReducer } from './GardenDashboardSlice/GardenDailyWorkGraphSlice/GardenDailWorkGraphSlice'
import { WorkTypeButtonGetReducer } from './MainCountSlice/MainWorkTypeButtonSlice'


export const rootReducer = {
    GardenMasterTableData: GardenMasterTableDataReducer,
    GardenMasterTableExportData: GardenMasterTableExportDataReducer,
    GardenMasterPostData: GardenMasterPostDataReducer,
    WardNameDDLData: WardNameDDLReducer,
    DailyPreventiveWorkTableData: DailyPreventiveWorkTableDataReducer,
    AssetDDLData: AssetDDLReducer,
    AssetTypeDDLData: AssetTypeDDLReducer,
    FinancialYearDDLData: FinancialYearDDLReducer,
    MonthDDLData: MonthDDLReducer,
    WorkAssignedTableData: WorkAssignedTableDataReducer,
    ReopenWorkProgressTableData: ReopenWorkProgressTableDataReducer,
    SectorDDLData: SectorDDLReducer,
    ZoneNameDDLData: ZoneNameDDLReducer,

    ContactPersonDDLData: ContactPersonDDLReducer,

    PlayEquipmentMasterTableData: PlayEquipmentMasterTableDataReducer,
    PlayEquipmentMasterTableExportData: PlayEquipmentMasterTableExportDataReducer,
    PlayEquipmentMasterPostData: PlayEquipmentMasterPostDataReducer,
    PlayEquipmentMasterDelete: PlayEquipmentMasterDeleteReducer,

    // -------------Master------------
    DepartmentDDLData: DepartmentDDLReducer,

    NewDepartmentDDLData: NewDepartmentDDLReducer,

    DepartmentTableData: DepartmentTableDataReducer,
    DepDeleteData: DepDeleteReducer,
    DesignationDDLData: DesignationDDLReducer,
    DesignationTableData: DesignationTableDataReducer,
    DesignationPostData: DesignationPostDataReducer,
    DesignationDeleteData: DesignationDeleteReducer,
    YesNoDDLData: YesNoDDLReducer,
    WardNameDDLData: WardNameDDLReducer,
    DailyPreventiveWorkTableData: DailyPreventiveWorkTableDataReducer,
    AssetDDLData: AssetDDLReducer,
    AssetTypeDDLData: AssetTypeDDLReducer,
    FinancialYearDDLData: FinancialYearDDLReducer,
    MonthDDLData: MonthDDLReducer,
    WorkAssignedTableData: WorkAssignedTableDataReducer,
    ReopenWorkProgressTableData: ReopenWorkProgressTableDataReducer,
    SectorDDLData: SectorDDLReducer,
    EmployeeDDLData: EmployeeDDLReducer,
    EmployeeTableData: EmployeeTableDataReducer,
    EmployeePostData: EmployeePostDataReducer,
    EmployeeDeleteData: EmployeeDeleteReducer,
    ZoneMasterTableData: ZoneMasterTableDataReducer,

    ZoneDDLData: ZoneDDLReducer,
    WardTableData: WardTableDataReducer,
    WardPostData: WardPostDataReducer,
    WardDeleteData: WardDeleteReducer,
    SectorTableData: SectorTableDataReducer,
    SectorPostData: SectorPostDataReducer,
    SectorDeleteData: SectorDeleteReducer,
    ProjectTableData: ProjectTableDataReducer,
    ProjectPostData: ProjectPostDataReducer,
    ProjectDeleteData: ProjectDeleteReducer,
    ZoneMasterPostData: ZoneMasterPostDataReducer,
    ContractorNameDDLData: ContractorNameDDLReducer,
    ContractorNameTableData: ContractorNameTableDataReducer,
    ContractorNamePostData: ContractorNamePostDataReducer,
    ContractorNameDeleteData: ContractorNameDeleteReducer,
    WorkMasterTableData: WorkMasterTableDataReducer,
    WorkMasterExportTableData: WorkMasterExportTableDataReducer,

    ZoneDDLData: ZoneDDLReducer,
    WardTableData: WardTableDataReducer,
    WardPostData: WardPostDataReducer,
    WardDeleteData: WardDeleteReducer,
    ProjectNameDDLData: ProjectNameDDLReducer,
    ProjectWiseWorkNameTableData: ProjectWiseWorkNameTableDataReducer,
    ProjectWiseWorkNamePostData: ProjectWiseWorkNamePostDataReducer,
    ProjectWiseWorkNameDeleteData: ProjectWiseWorkNameDeleteReducer,
    WorkTypeDDLData: WorkTypeDDLReducer,
    WorkTypeTableData: WorkTypeTableDataReducer,
    WorkSubTypeTableData: WorkSubTypeTableDataReducer,
    WorkTypePostData: WorkTypePostDataReducer,
    WorkTypeDeleteData: WorkTypeDeleteReducer,
    WorkSubTypePostData: WorkSubTypePostDataReducer,
    WorkSubTypeDeleteData: WorkSubTypeDeleteReducer,
    ZoneMasterDeleteData: ZoneMasterDeleteReducer,
    WorkSubTypeDDLData: WorkSubTypeDDLReducer,
    WorkNameDDLData: WorkNameDDLReducer,
    UpdateWorkNameDDL: UpdateWorkNameDDLReducer,
    ProjectWiseContractorTableData: ProjectWiseContractorTableDataReducer,
    LocationNameDDLData: LocationNameDDLReducer,
    LocationNameMasterTableData: LocationNameMasterTableDataReducer,
    LocationNameMasterPostData: LocationNameMasterPostDataReducer,
    GardenDashMainCountData: GardenDashMainCountDataReducer,
    ProjectWiseContractorPostData: ProjectWiseContractorPostDataReducer,
    ProjectWiseContractorDeleteData: ProjectWiseContractorDeleteReducer,
    WorkUploadTableData: WorkUploadTableDataReducer,
    ProjectTypeNameDDLData: ProjectTypeNameDDLReducer,
    RoleNameDDLData: RoleNameDDLReducer,

    NewRoleDDLData: NewRoleDDLReducer,

    StatusDDLData: StatusDDLReducer,
    GDProjectNameDDLData: GDProjectNameDDLReducer,

    GardenMasterDeleteData: GardenMasterDeleteReducer,

    GardenCompletedWorkReportTableData: GardenCompletedWorkReportTableDataReducer,
    GardenCompletedWorkPDFReportTableData: GardenCompletedWorkPDFReportTableDataReducer,

    // ----------------Road Divider ------
    WorkProgressTableData: WorkProgressTableDataReducer,
    WorkApprovalTableData: WorkApprovalTableDataReducer,
    RoadWorkNameDDLData: RoadWorkNameDDLReducer,
    ProjectWiseContractorPostData: ProjectWiseWorkNamePostDataReducer,

    WorkUploadTableData: WorkUploadTableDataReducer,
    LocationNameMasterDeleteData: LocationNameMasterDeleteReducer,
    WorkNameTableData: WorkNameTableDataReducer,
    WardWiseWorkTableData: WardWiseWorkTableDataReducer,
    UserCreationTableData: UserCreationTableDataReducer,
    UserCreationExportTableData: UserCreationExportTableDataReducer,
    UserCreationPostData: UserCreationPostDataReducer,
    UserCreationDeleteData: UserCreationDeleteReducer,
    WorkNameMasterPostData: WorkNameMasterPostDataReducer,
    WorkNameMasterDeleteData: WorkNameMasterDeleteReducer,
    RoadDashMainCountData: RoadDashMainCountDataReducer,
    GetMapViewData: GetMapViewDataReducer,

    // ---------------------Severage dashboard-----------------
    SewerageDashMainCountData: SewerageDashMainCountDataReducer,
    LocationMasterTableData: LocationMasterTableDataReducer,
    LocationMasterPostData: LocationMasterPostDataReducer,
    LocationMasterDeleteData: LocationMasterDeleteReducer,
    PumpingStationTableData: PumpingStationTableDataReducer,
    PumpingStationPostData: PumpingStationPostDataReducer,
    PumpingStationDeleteData: PumpingStationDeleteReducer,
    AreaMasterTableData: AreaMasterTableDataReducer,
    AreaMasterPostData: AreaMasterPostDataReducer,
    AreaMasterDeleteData: AreaMasterDeleteReducer,
    SeverageWorkTypeData: SeverageWorkTypeReducer,
    SeverageWorkTypeTableData: SeverageWorkTypeTableDataReducer,
    SeverageWorkTypePostData: SeverageWorkTypePostDataReducer,
    SeverageWorkTypeDeleteData: SeverageWorkTypeDeleteReducer,
    SeverageWorkNameTableData: SeverageWorkNameTableDataReducer,
    SeverageWorkNamePostData: SeverageWorkNamePostDataReducer,
    SeverageWorkNameDeleteData: SeverageWorkNameDeleteReducer,
    PumpingStationData: PumpingStationReducer,
    PumpingStationMasterTableData: PumpingStationMasterTableDataReducer,
    PumpingStationMasterPostData: PumpingStationMasterPostDataReducer,
    PumpingStationMasterDelete: PumpingStationMasterDeleteReducer,
    PumpingOperationTableData: PumpingOperationTableDataReducer,
    SeverageWorkTypeData: SeverageWorkTypeReducer,
    SeverageWorkTypeTableData: SeverageWorkTypeTableDataReducer,
    SeverageWorkTypePostData: SeverageWorkTypePostDataReducer,
    SeverageWorkTypeDeleteData: SeverageWorkTypeDeleteReducer,
    SeverageWorkNameTableData: SeverageWorkNameTableDataReducer,
    SeverageWorkNamePostData: SeverageWorkNamePostDataReducer,
    SeverageWorkNameDeleteData: SeverageWorkNameDeleteReducer,
    PumpingStationData: PumpingStationReducer,
    PumpingStationMasterTableData: PumpingStationMasterTableDataReducer,
    PumpingStationMasterPostData: PumpingStationMasterPostDataReducer,
    PumpingStationMasterDelete: PumpingStationMasterDeleteReducer,
    WorkPeriodDDLData: WorkPeriodDDLReducer,
    DailyWorkSecurityTableData: DailyWorkSecurityTableDataReducer,
    EnergyAuditTableData: EnergyAuditTableDataReducer,
    HouseKeepingTableData: HouseKeepingTableDataReducer,
    WorkAssignByDDLData: WorkAssignByDDLReducer,
    ServicesTableData: ServicesTableDataReducer,
    ServicesMasterDeleteData: ServicesMasterDeleteReducer,
    PenaltyWorkReportTableData: PenaltyWorkReportTableDataReducer,
    PenaltyWorkReportPDFTableData: PenaltyWorkReportPDFTableDataReducer,
    RoadWorkReportCompleteTableData: RoadWorkReportCompleteTableDataReducer,
    SeverageLocationDDLData: SeverageLocationDDLReducer,
    SeveragePipelineDetailsMasterTableData: SeveragePipelineDetailsMasterTableDataReducer,
    SeveragePipelineDetailsMasterPostData: SeveragePipelineDetailsMasterPostDataReducer,
    SeveragePipelineDetailsMasterDeleteData: SeveragePipelineDetailsMasterDeleteReducer,
    ServiceTypeDDLData: ServiceTypeDDLReducer,
    // CustomerServiceTableData:CustomerServiceTableDataReducer,
    CustomerServiceTableData: CustomerServiceTableDataReducer,
    AssignMechCivilPendingDeleteData: AssignMechCivilPendingDeleteReducer,
    CustomerServiceMasterDelete: CustomerServiceMasterDeleteReducer,
    PumpingOperationDelete: PumpingOperationDeleteReducer,
    DailyWorkSecurityDelete: DailyWorkSecurityDeleteReducer,
    DailyWorkEnergyAuditDelete: DailyWorkEnergyAuditDeleteReducer,
    DailyWorkHouseKeepingDelete: DailyWorkHouseKeepingDeleteReducer,

    // ---------------------Water Supply Dashboard---------------------

    WaterSupplyPumpStationTableData: WaterSupplyPumpStationTableDataReducer,
    WaterSupplyPumpStationPostData: WaterSupplyPumpStationPostDataReducer,
    WaterSupplyPumpStationMasterDelete: WaterSupplyPumpStationMasterDeleteReducer,
    WaterSupplyPipelineTableData: WaterSupplyPipelineTableDataReducer,
    WaterSupplyPipelineMasterDelete: WaterSupplyPipelineMasterDeleteReducer,
    WaterSupplyUserRoleDataDDL: WaterSupplyUserRoleDDLReducer,
    WaterSupplyUserDataDDL: WaterSupplyUserDDLReducer,
    WaterSupplyUserWiseWardMappingTableData: WaterSupplyUserWiseWardMappingTableDataReducer,
    UserWiseWardMappingPostData: UserWiseWardMappingPostReducer,
    PHTableData: PHTableDataReducer,
    PHPostData: PHPostDataReducer,
    PHDeleteData: PHDeleteReducer,
    WaterSupplyLocationDDLData: WaterSupplyLocationDDLReducer,
    ChlorineTableData: ChlorineTableDataReducer,
    ChlorinePostData: ChlorinePostDataReducer,
    ChlorineDeleteData: ChlorineDeleteReducer,
    TurbidityTableData: TurbidityTableDataReducer,
    TurbidityPostData: TurbidityPostDataReducer,
    TurbidityDeleteData: TurbidityDeleteReducer,
    EColiTableData: EColiTableDataReducer,
    EColiPostData: EColiPostDataReducer,
    EColiDeleteData: EColiDeleteReducer,
    JunctionNameDDLData: JunctionNameDDLReducer,
    WaterPressureTableData: WaterPressureTableDataReducer,
    WaterPressurePostData: WaterPressurePostDataReducer,
    WaterPressureDeleteData: WaterPressureDeleteReducer,
    WaterAuditTableData: WaterAuditTableDataReducer,
    WaterAuditPostData: WaterAuditPostDataReducer,
    WaterAuditDelete: WaterAuditDeleteReducer,
    EnergyConsumptionTableData: EnergyConsumptionTableDataReducer,
    EnergyConsumptionPostData: EnergyConsumptionPostDataReducer,
    EnergyConsumptionDelete: EnergyConsumptionDeleteReducer,
    WaterFlowTableData: WaterFlowTableDataReducer,
    WaterFlowPostData: WaterFlowPostDataReducer,
    WaterFlowDeleteData: WaterFlowDeleteReducer,
    WaterSupplyPumpingStationDDLData: WaterSupplyPumpingStationDDLReducer,
    ChemicalSupplyTableData: ChemicalSupplyTableDataReducer,
    ChemicalSupplyPostData: ChemicalSupplyPostDataReducer,
    ChemicalSupplyDelete: ChemicalSupplyDeleteReducer,
    EcoliDDLData: EcoliDDLReducer,
    SecurityTableData: SecurityTableDataReducer,
    SecurityDeleteData: SecurityDeleteReducer,
    HouseKeepingTableData: HouseKeepingTableDataReducer,
    HouseKeepingDeleteData: HouseKeepingDeleteReducer,
    ValveOperationTableData: ValveOperationTableDataReducer,
    ValveOperationDeleteData: ValveOperationDeleteReducer,



    NewDashMainCountData: NewDashMainCountDataReducer,


    CommWorkAssignedTableDataAPIData: CommWorkAssignedTableDataAPIReducer,
    CommWorkReopenCountData: CommWorkReopenCountDataAPIReducer,
    CommContractorDDLData: CommContractorDDLReducer,
    CommWorkProgressCountData: CommWorkProgressCountDataAPIReducer,


    // WorkAssignByOfficialsCommCountDDL: WorkAssignByOfficialsCommCountDDLReducer,
    HorticultureWorkAssignedTableData: HorticultureWorkAssignedTableDataReducer,
    CommWorkReopenGetAPIData: CommWorkReopenGetDataAPIReducer,
    CommWorkProgressTableDataAPIData: CommWorkProgressTableDataAPIReducer,
    ContractorWiseAssetDDLData: ContractorWiseAssetDDLDataReducer,
    ContractorWiseAssetPostData: ContractorWiseAssetPostDataReducer,
    ContractorWiseAssetTableData: ContractorWiseAssetTableDataReducer,

    ContractorWiseAssetTableExportData: ContractorWiseAssetTableExportDataReducer,

    WorkTypeWiseContractorDDLData: WorkTypeWiseContractorDDLReducer,

    // -------------------------------------Garden Dashboard ----------------------------------

    HorticultureWorkAssignedCountData: HorticultureWorkAssignedCountDataReducer,

    MaintainByDDLData: MaintainByDDLDataReducer,

    AssetNameDDLData: AssetNameDDLDataReducer,

    EquipmentNameDDLData: EquipmentNameDDLReducer,

    WardWiseAssetNameDDLData: WardWiseAssetNameDDLReducer,

    SportsWorkAssignedTableData: SportsWorkAssignedTableDataReducer,

    AssetWiseEquipmentNameDDLData: AssetWiseEquipmentNameDDLReducer,

    CommNewDepartmentDDLData: CommNewDepartmentDDLReducer,
    DailyWorkSheetTableData: DailyWorkSheetTableDataReducer,
    DailyWorkSheetTablePDFData: DailyWorkSheetTablePDFDataReducer,
    DepartmentWiseEmployeeDDLData: DepartmentWiseEmployeeDDLReducer,


    HorticultureWorkAssignedTableExportData: HorticultureWorkAssignedTableExportDataReducer,

    GardenMasterGeoLocationData: GardenMasterGeoLocationDataReducer,
    DepartmentWiseDesignationDDLData: DepartmentWiseDesignationDDLReducer,

    SportWorkProgressTableData: SportWorkProgressTableDataAPIReducer,
    SportWorkReopenByContractorTableData: SportWorkReopenByContractorTableDataAPIReducer,
    ContractorNameDDLNewData: ContractorNameDDLNewReducer,
    ContractorWiseWardSelectDDLData: ContractorWiseWardSelectDDLReducer,
    ContractorNameReportDDLData: ContractorNameReportDDLReducer,

    PlayEquipmentMasterQrReportData: PlayEquipmentMasterQrReportDataReducer,

    DailyWorkSheetDesignationDDLData: DailyWorkSheetDesignationDDLReducer,



    NewScreenDailyPreventiveWorkTableData: NewScreenDailyPreventiveWorkTableDataReducer,
    NewScreenDailyPreventiveWorkCountPopupData: NewScreenDailyPreventiveWorkCountPopupDataReducer,


    CommonOfficerWorkStatusTableData: CommonOfficerWorkStatusTableDataReducer,
    CommonOfficerWorkStatusCountPopupTableData: CommonOfficerWorkStatusCountPopupTableDataReducer,
    DailyWorkSheetPDFData : DailyWorkSheetPDFDataReducer,

    PreventiveWorkAssetNameDDLData:PreventiveWorkAssetNameDDLDataReducer,
    DailyPieChartData : DailyPieChartDataReducer,
    GardenDailyWorkBarChartData:GardenDailyWorkBarChartReducer,
    GetChecklistProgressSelectData : GetChecklistProgressSelectDataReducer,
    ChecklistProgressWiseEmployeeSelectData:ChecklistProgressWiseEmployeeSelectDataReducer,
    ChecklistProgressWiseEmplDailyPendingCompletedSelectData:ChecklistProgressWiseEmplDailyPendingCompletedSelectDataReducer,
    WorkTypeButtonGetData:WorkTypeButtonGetReducer,
}

