
import { useEffect, useState } from "react"
import VisitAchievePendingModal from "./Modal/VisitAchievePendingModal"
import { useDispatch, useSelector } from "react-redux"
import { GetChecklistProgressSelectDataAPI } from "../../../../../Redux/GardenDashboardSlice/DailyPreventiveWorkSlice"
import { useAuthState } from "../../../../../Helper/Context"
import { Loading } from "../../../../../Helper/Loading"
import moment from "moment";
import { useTranslation } from "react-i18next"
import EmployeeAndContractorWorkPopup from "../../GarderDashboardComponent/EmployeeAndContractorWiseWorkPending/CountPopup/TotalCountPopup"

export const RoadWorkDailyChecklist = (props) => {
    const {
        YearValue,
        MonthValue,
        WardValue,
        ZoneValue,
        AssetType,
        Date,
        Language,
        WorkActiveButton,
        ActiveButton,
    } = props

    const { t } = useTranslation()

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails

    const [OpenModal, setOpenModal] = useState(false)
    const [Flag, setFlag] = useState('')

    const [tableData, settableData] = useState([])

    const [apiFlag, setapiFlag] = useState('')
    const [Data, setData] = useState('')
    const [loader, setloader] = useState(false)

    const [EmployeeAndContractorWorkPopupShow, setEmployeeAndContractorWorkPopupShow] = useState({
        show: false,
        data: '',
        ShowBy: ''
    })

    const handleEmployeeAndContractorWorkPopup = (data, ShowBy) => {
        setEmployeeAndContractorWorkPopupShow({
            show: true,
            data: data,
            ShowBy: ShowBy
            // flag: flag
        })
    }


    const handleOnCloseClick = () => {
        setEmployeeAndContractorWorkPopupShow({
            show: false,
            data: '',
            // flag: flag
        })
    }

    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: ''
    // })

    // useEffect(() => {
    //     setLanguage({
    //         ...Language,
    //         DDL: [],
    //         ID: 0,
    //         Label: localStorage.getItem('LanguageChange'),
    //     })
    // }, [])

    const handleOpenModal = (flag, data, APIFlag) => {
        setOpenModal(!OpenModal)
        setFlag(flag)
        setData(data)
        setapiFlag(APIFlag)
    }

    useEffect(() => {
        setloader(true)
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            M_ZoneID: 0,
            M_AssetTypeID: AssetType,
            M_WorkTypeID: '1',
            M_WardID: WardValue,
            Date: Date,
            M_UsersID: UserID,
            token: token,
            From: 1,
            To: 99999,
            Flag: 'Garden',
            handleGetData: handleRoadData,
            Language: Language.Label,
            setloader: setloader
        }

        // const data = {
        //     M_FinancialYearID: '2024',
        //     M_MonthID: '1',
        //     M_ZoneID: '0',
        //     M_AssetTypeID: AssetType,
        //     M_WardID: '0',
        //     M_UsersID: '0',
        //     token: token,
        //     From: 1,
        //     To: 99999,
        //     Flag: 'Garden',
        //     handleGetData:handleRoadData
        // }
        {
            (YearValue && (Language.Label != undefined && Date != undefined) && Language.Label != '') &&
                dispatch(GetChecklistProgressSelectDataAPI({ data }))
        }

    }, [YearValue, MonthValue, ZoneValue, WardValue, Date, Language.Label])

    const handleRoadData = (result) => {
        settableData(result)
    }

    const { isLoading } = useSelector(state => state.GetChecklistProgressSelectData)


    return (
        <>

            <div className="row pt-1 ps-3 pb-3 pe-0">
                {loader && <Loading />}
                <div className="col-12 mb-1 ps-0  pe-0 " >
                    <div style={{ height: '65vh' }} className='row mob_table table-responsive px-2 pt-2'>
                        <table id="totalordergenerated"
                            cellPadding="0"
                            cellSpacing="0"
                            border="0"
                            className="table table-bordered " style={{ marginBottom: "0px" }} >
                            <thead className="gardenworktable" id="customers" style={{ position: 'sticky', top: -10 }}>
                                {/* <tr className="text-center ">
                                <th colSpan={20} className="text-center fw-bold fs-5" style={{ background: '#696969' }}>Garden Work Daily Checklist Progress</th>
                            </tr> */}

                                <tr className="text-center tablethhead">
                                    {
                                        tableData && tableData.mainHeading && tableData.mainHeading.length > 0 && tableData.mainHeading.map((i) => {
                                            return (
                                                <th colSpan={i.no} className="fw-bold fs-6 verticalAllign" id="tablecolor">{i.manihead}</th>

                                            )
                                        })
                                    }
                                </tr>

                                <tr className="text-center gardenworkth" style={{ width: '100%' }}>
                                    {
                                        tableData && tableData.heading && tableData.heading.length > 0 && tableData.heading.map((j) => {
                                            // <></>
                                            return (
                                                <th className="text-center fw-bold break" style={{ whiteSpace: 'nowrap' }}>{j.head}</th>
                                            )
                                        })
                                    }
                                </tr>

                            </thead>
                            <tbody>
                                {tableData && tableData.data && tableData.data.length > 0 ? tableData.data.map((designation) => {
                                    return designation?.rowdata?.map((officer, officerIndex) => {
                                        const rowSpan = designation?.rowdata?.length || 1;
                                        return (
                                            <tr className="text-center" key={`${designation?.designationName}-${officerIndex}`}>

                                                {officerIndex === 0 ? (
                                                    <td rowSpan={rowSpan} className="verticalAllign">{designation?.designationName}</td>
                                                ) : null}

                                                <td className="verticalAllign">{officer?.employeeName}</td>
                                                <td className="text-center verticalAllign">{officer?.zoneName}</td>
                                                <td className="text-center verticalAllign cursor-pointer bluecolorCount" onClick={(e) => officer?.week1_VisitTarget == 0 ? e.preventDefault() : handleOpenModal('Visit Target', officer, 'VisitTarget_Week1')}>{officer?.week1_VisitTarget}</td>
                                                <td className="text-center verticalAllign cursor-pointer greencolorCount" onClick={(e) => officer?.week1_Achievement == 0 ? e.preventDefault() : handleOpenModal('Achievement', officer, 'Achievement_Week1')}>{officer?.week1_Achievement}</td>
                                                <td className="text-center verticalAllign cursor-pointer orangecolorCount" onClick={(e) => officer?.week1_Pending == 0 ? e.preventDefault() : handleOpenModal('Pending', officer, 'Pending_Week1')}>{officer?.week1_Pending}</td>

                                                <td className="text-center verticalAllign cursor-pointer bluecolorCount " onClick={(e) => officer?.week2_VisitTarget == 0 ? e.preventDefault() : handleOpenModal('Visit Target', officer, 'VisitTarget_Week2')}>{officer?.week2_VisitTarget}</td>
                                                <td className="text-center verticalAllign cursor-pointer greencolorCount" onClick={(e) => officer?.week2_Achievement == 0 ? e.preventDefault() : handleOpenModal('Achievement', officer, 'Achievement_Week2')}>{officer?.week2_Achievement}</td>
                                                <td className="text-center verticalAllign cursor-pointer orangecolorCount" onClick={(e) => officer?.week2_Pending == 0 ? e.preventDefault() : handleOpenModal('Pending', officer, 'Pending_Week2')}>{officer?.week2_Pending}</td>

                                                <td className="text-center verticalAllign cursor-pointer bluecolorCount" onClick={(e) => officer?.week3_VisitTarget == 0 ? e.preventDefault() : handleOpenModal('Visit Target', officer, 'VisitTarget_Week3')}>{officer?.week3_VisitTarget}</td>
                                                <td className="text-center verticalAllign cursor-pointer greencolorCount" onClick={(e) => officer?.week3_Achievement == 0 ? e.preventDefault() : handleOpenModal('Achievement', officer, 'Achievement_Week3')}>{officer?.week3_Achievement}</td>
                                                <td className="text-center verticalAllign cursor-pointer orangecolorCount" onClick={(e) => officer?.week3_Pending == 0 ? e.preventDefault() : handleOpenModal('Pending', officer, 'Pending_Week3')}>{officer?.week3_Pending}</td>

                                                <td className="text-center verticalAllign cursor-pointer bluecolorCount" onClick={(e) => officer?.week4_VisitTarget == 0 ? e.preventDefault() : handleOpenModal('Visit Target', officer, 'VisitTarget_Week4')}>{officer?.week4_VisitTarget}</td>
                                                <td className="text-center verticalAllign cursor-pointer greencolorCount" onClick={(e) => officer?.week4_Achievement == 0 ? e.preventDefault() : handleOpenModal('Achievement', officer, 'Achievement_Week4')}>{officer?.week4_Achievement}</td>
                                                <td className="text-center verticalAllign cursor-pointer orangecolorCount" onClick={(e) => officer?.week4_Pending == 0 ? e.preventDefault() : handleOpenModal('Pending', officer, 'Pending_Week4')}>{officer?.week4_Pending}</td>

                                                {
                                                    (officer?.week5_VisitTarget && officer?.week5_VisitTarget != undefined) &&
                                                    <>
                                                        <td className="text-center verticalAllign cursor-pointer bluecolorCount" onClick={(e) => officer?.week5_VisitTarget == 0 ? e.preventDefault() : handleOpenModal('Visit Target', officer, 'VisitTarget_Week5')}>{officer?.week5_VisitTarget}</td>
                                                        <td className="text-center verticalAllign cursor-pointer greencolorCount" onClick={(e) => officer?.week5_Achievement == 0 ? e.preventDefault() : handleOpenModal('Achievement', officer, 'Achievement_Week5')}>{officer?.week5_Achievement}</td>
                                                        <td className="text-center verticalAllign cursor-pointer orangecolorCount" onClick={(e) => officer?.week5_Pending == 0 ? e.preventDefault() : handleOpenModal('Pending', officer, 'Pending_Week5')}>{officer?.week5_Pending}</td>
                                                    </>
                                                }

                                                <td className="text-center verticalAllign cursor-pointer bluecolorCount" onClick={(e) => officer?.total_VisitTarget == 0 ? e.preventDefault() : handleOpenModal('Visit Target', officer, 'VisitTarget_Total')}>{officer?.total_VisitTarget}</td>
                                                <td className="text-center verticalAllign cursor-pointer greencolorCount" onClick={(e) => officer?.total_Achievement == 0 ? e.preventDefault() : handleOpenModal('Achievement', officer, 'Achievement_Total')}>{officer?.total_Achievement}</td>
                                                <td className="text-center verticalAllign cursor-pointer bluecolorCount" onClick={(e) => officer?.total_Pending == 0 ? e.preventDefault() : handleOpenModal('Pending', officer, 'Pending_Total')}>{officer?.total_Pending}</td>
                                                <td className="text-center verticalAllign fw-bold">
                                                    <span className='cursor-pointer fw-bold'
                                                        onClick={() => handleEmployeeAndContractorWorkPopup(officer, 'All')}>
                                                        {officer?.kpI_Assign}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    });
                                })
                                    :
                                    <div className='loadingcenter d-flex justify-content-center align-items-center' style={{ height: '33vh', marginTop: '-3%' }}>
                                        <img
                                            src="static/assets/img/folder.png"
                                            style={{ height: "40px", marginLeft: "10px" }}
                                        /><label className='norecordlabel'>&nbsp;&nbsp;No Record Found ...</label>
                                    </div>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
            {
                OpenModal &&
                <VisitAchievePendingModal
                    handleOpenModal={handleOpenModal}
                    Title={Flag == 'Visit Target' ? t('EmployeeAndContractor.VisitTarget') : Flag == 'Achievement' ? t('EmployeeAndContractor.Achievement') : t('EmployeeAndContractor.Pending')}
                    Flag={Flag}
                    YearValue={YearValue}
                    MonthValue={MonthValue}
                    WardValue={WardValue}
                    ZoneValue={ZoneValue}
                    AssetType={AssetType}
                    Data={Data}
                    apiFlag={apiFlag}
                />
            }
            {
                EmployeeAndContractorWorkPopupShow.show ?
                    <EmployeeAndContractorWorkPopup
                        open={EmployeeAndContractorWorkPopupShow.show}
                        cancelbtn={handleOnCloseClick}
                        ActiveButton={ActiveButton}
                        GridData={EmployeeAndContractorWorkPopupShow.data}
                        PopUpApiFlag={EmployeeAndContractorWorkPopupShow.flag}
                        ShowBy={EmployeeAndContractorWorkPopupShow.ShowBy}
                        FromDate=''
                        ToDate=''
                        WorkType='0'
                        ZoneNameDDLID='0'
                        WardName='0'
                        flag="Daily Work"
                        YearValue={YearValue}
                        MonthValue={MonthValue}
                        WorkActiveButton='1'
                        AssetType = {AssetType}
                    />
                    : <></>
            }
        </>
    )
}