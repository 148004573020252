

import ReactHTMLTableToExcel from "react-html-table-to-excel"
import { useTranslation } from "react-i18next"
import moment from 'moment'

export const ExportEmployeeAndContractor = (props) => {
    const { name, ActiveButton, tableData, ToDate, FromDate, WardName, ZoneNameDDLID, WorkType } = props

    const { t } = useTranslation()

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="btn btn-primary download-table-xls-button mt-4 btn btn-export btn-md mr-1 mb-2 ml-1"
                filename={name}
                sheet={name}
                buttonText="Excel Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '47%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>
                {/* <h4 style={{ textAlign: 'center', fontSize: 16 }}>Navi Mumbai Muncipal Corporation</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>STP Maintenance Department</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>Project Name - {ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table[0].projectName : '-'}</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>Report Name - {name}</h4> */}
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>
                    From Date - {FromDate ? moment(FromDate).format('DD-MM-YYYY') : ''}
                    <span>&emsp;</span>
                    | To Date - {ToDate ? moment(ToDate).format('DD-MM-YYYY') : ''}
                    <span>&emsp;</span>
                </h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>
                    Work Type -{WorkType}
                    <span>&emsp;</span>
                    |  Zone Name - {ZoneNameDDLID}
                    <span>&emsp;</span>
                    | Ward Name - {WardName}
                </h4>

                <tr><td></td></tr>

                <thead>
                    <tr style={{ backgroundColor: '#254462', color: "#fff", border: '1px solid white', borderTop: "1px solid black" }}>
                        <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                        <th>
                            {
                                ActiveButton == 'कर्मचारी' ?
                                    <>
                                        {t('Dashboard.Work_Assigned_by_Officials.Officer_Name')}
                                    </>
                                    :
                                    <>
                                        {t('Dashboard.Work_Assigned_by_Officials.Contractor_Name')}
                                    </>
                            }
                        </th>
                        {/* <th>Department Name</th> */}
                        <th>{t('Dashboard.Titles.Ward_Name')}</th>
                        <th>{t('EmployeeAndContractor.Total_Assign_Works')}</th>
                        <th>{t('EmployeeAndContractor.Total_works_Completed')}</th>
                        <th>{t('EmployeeAndContractor.Total_Pending_Works')}</th>
                        <th>{t('EmployeeAndContractor.0_to_7_days')}</th>
                        <th>{t('EmployeeAndContractor.7_to_15_days')}</th>
                        <th>{t('EmployeeAndContractor.15_to_30_days')}</th>
                        <th>{t('EmployeeAndContractor.30_to_90_days')}</th>
                        <th>{t('EmployeeAndContractor.90_to_180_days')}</th>
                        <th>{t('EmployeeAndContractor.1_year')}</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((i) => (
                            <tr style={{ width: '100%' }}>
                                <td style={{ textAlign: "center" }}>{i?.rowNum}</td>
                                <td>{i.employeeName ? i.employeeName : '-'}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{i.wardName ? i.wardName : '-'}</td>
                                <td style={{ color: '#4d8ae1', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.totalAllCount ? i.totalAllCount : 0}
                                </td>
                                <td style={{ color: 'green', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.completedCount ? i.completedCount : 0}
                                </td>
                                <td style={{ color: 'orangered', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.pendingCount ? i.pendingCount : 0}
                                </td>
                                <td style={{ color: '#fab74b', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.pending_0_7DaysCount ? i.pending_0_7DaysCount : 0}
                                </td>

                                <td style={{ color: '#f7526f', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.pending_7_15DaysCount ? i.pending_7_15DaysCount : 0}
                                </td>

                                <td style={{ color: '#30a1f5', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.pending_15_30DaysCount ? i.pending_15_30DaysCount : 0}
                                </td>

                                <td style={{ color: '#db0008', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.pending_30_90DaysCount ? i.pending_30_90DaysCount : 0}
                                </td>

                                <td style={{ color: '#77fb0a', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.pending_90_180DaysCount ? i.pending_90_180DaysCount : 0}
                                </td>

                                <td style={{ color: '#6464ed', textAlign: "center", fontWeight: 'bold' }}>
                                    {i.pending_365DaysCount ? i.pending_365DaysCount : 0}
                                </td>

                            </tr>
                        ))
                            :
                            <>No Data Found</>
                    }

                </tbody>

            </table>
        </div>
    )
}