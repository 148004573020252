import Header from "../../../../Components/Header/Header";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../Helper/Context";
import { DailyPreventiveWorkTableDataAPI, NewScreenDailyPreventiveWorkTableDataAPI } from "../../../../Redux/GardenDashboardSlice/DailyPreventiveWorkSlice";
import ViewURL from "../../../../Components/ViewURL/ViewURL";
import { Pegination } from "../../../../Components/Pegination/Pegination";
import { WardNameDataDDL } from "../../../../Components/CommonDDL/WardNameDataDDL";
import { WardNameDDLAPI } from "../../../../Redux/DDLSlice";
import moment from "moment";
import { Loading } from "../../../../Helper/Loading";
import { DashBoardScreenWardDDL } from "../../../../Components/CommonDDL/DashboardScreenWardDDL";
import { ZoneDataDDLS } from "../../../../Components/CommonDDL/ZoneDataDDLS";
import { EmployeeDataDDL } from "../../../../Components/CommonDDL/EmployeeDataDDL";
import { t } from "i18next";
import DailyWorkPopup from "./Popup/DailyWorkPopup";
import { DesignationDDL } from "../../../../Components/CommonDDL/DesignationDDL";
import { CommMasterDepartmentDDL } from "../../../../Components/CommonDDL/CommMasterDepartmentDDL";
import { DailyWorkSheetDesignationDDL } from "../../../../Components/CommonDDL/DailyWorkSheetDesignationDDL";
import CommonDashboardScreenWardDDL from "../../../../Components/CommonDDL/CommonDashboardScreenWardDDL";

export default function GardenDailyPreWorkPending() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")

    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let ZWard = searchParams.get("ZoneName")
    let ZLable = searchParams.get("ZLable")

    const d = new Date();
    let month = d.getMonth();

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [FromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImageHeading, setImageHeading] = useState('')

    const [DailyWorkPopupShow, setDailyWorkPopupShow] = useState({
        show: false,
        data: ''
    })
    const [DailyWorkPopupHeading, setDailyWorkPopupHeading] = useState('')

    const [GridData, setGridData] = useState([]);

    const handleOnCloseClick = () => {
        setDailyWorkPopupShow({
            show: false,
            data: ''
        })
    }

    const handleDailyWorkTotalCountPopupShow = (data, flag) => {
        setDailyWorkPopupShow({
            show: true,
            data: data,
            flag: flag
        })
    }

    // const [PopUpField, setPopUpField] = React.useState({
    //     addPopUp: false,
    //     deletePopUp: false,
    //     popupFlag: '',
    //     popupBtn: "",
    //     apiFlag: "",
    //     rowData: ''
    // })

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate(moment().format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setZoneName({
            ...ZoneName,
            ID: 0,
            Label: 'Select',
        })
        setEmployeeDDL({
            ...EmployeeDDL,
            ID: 0,
            Label: 'Select',
        })
        setDailyWorkSheetDesignation({
            ...DailyWorkSheetDesignation,
            ID: 0,
            Label: 'Select',
        })

    }

    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [EmployeeDDL, setEmployeeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Designation, setDesignation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Department, setDepartment] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...',
    })

    const [DailyWorkSheetDesignation, setDailyWorkSheetDesignation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    useEffect(() => {
        // if (FromDate != moment().startOf('month').format('YYYY-MM-DD')) {
        if (FromDate != moment().format('YYYY-MM-DD')) {
            setToDate('')
        }
    }, [FromDate])

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    // useEffect(() => {
    //     const data = {
    //         M_WardID: WardName.ID,
    //         FromkDate: FromDate,
    //         TokDate: ToDate,
    //         M_UsersID: UserID,
    //         token: token,
    //         From: From,
    //         To: To,
    //         Flag: ApiFlag,

    //     }
    //     dispatch(DailyPreventiveWorkTableDataAPI({ data }))

    // }, [IsPost, IsSearch, To, IsClear, WardName.ID])


    // const { tableData, isLoading } = useSelector(state => state.DailyPreventiveWorkTableData)


    useEffect(() => {
        const data = {
            T_KPIUploadDailyWorkID: 0,
            FromDate: FromDate,
            ToDate: ToDate,
            M_ZoneID: ZoneName.ID,
            M_OfficerID: EmployeeDDL.ID,
            M_DesignationID: DailyWorkSheetDesignation.ID,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: ApiFlag,
            Language: Language.Label,
            setGridData: setGridData
        }
        { Language.Label != '' && dispatch(NewScreenDailyPreventiveWorkTableDataAPI({ data })) }

    }, [IsPost, IsSearch, To, IsClear, ZoneName.ID, EmployeeDDL.ID, DailyWorkSheetDesignation.ID, FromDate, ToDate, Language.Label])
    const { tableData, isLoading } = useSelector(state => state.NewScreenDailyPreventiveWorkTableData)
    // console.log('GridData', GridData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [ZoneName.ID, EmployeeDDL.ID, DailyWorkSheetDesignation.ID,])

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} Language={Language} />
                    <div id="" className="content1 content">
                        <div className="data_mob">
                            <div className="row m-1 pb-4 master_border">
                                <div className="col-8">

                                    {
                                        searchName == 'Completed' || searchName == 'पूर्ण झालेली कामे' ?
                                            <h5 className="ps-1 py-2">
                                                {t('AdminDashboard.Admin_Titles.Daily_Work_Completed')}
                                            </h5>
                                            :
                                            <h5 className="ps-1 py-2">
                                                {t('EmployeeAndContractor.Daily_Work')}
                                            </h5>
                                    }

                                </div>
                                {/* <div className="col-4 mt-2">
                                    <button type="button" className="btn btn-primary float-end mr-1 mb-2">Export</button>
                                </div> */}
                                <div className="row m-0">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">

                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">{t('Dashboard.Work_Assigned_by_Officials.From_Date')} :</label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => setFromDate(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">{t('Dashboard.Work_Assigned_by_Officials.To_Date')} :</label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            max={moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => setToDate(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    {/* <ZoneDataDDLS
                                                        ZoneName={ZoneName}
                                                        setZoneName={setZoneName}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                    /> */}
                                                    <CommonDashboardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        ZLable={ZLable}
                                                        ZWard={ZWard}
                                                        Language={Language}
                                                        ZoneNameDDLID={ZoneName}
                                                        setZoneNameDDLID={setZoneName}
                                                    // setWardValue={setWardValue}
                                                    // setWardLabel={setWardLabel}
                                                    // setZoneValue={setZoneValue}
                                                    // setZoneLabel={setZoneLabel}
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-3">
                                                    <CommMasterDepartmentDDL
                                                        Department={Department}
                                                        setDepartment={setDepartment}
                                                        Flag='DailyAttendanceSheet'
                                                    />

                                                </div> */}
                                                {/* <div className="col-12 col-md-4 col-lg-3">
                                                    <DesignationDDL
                                                        Designation={Designation}
                                                        setDesignation={setDesignation}
                                                        IsPost={IsPost}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-3 ">
                                                    <EmployeeDataDDL
                                                        EmployeeDDL={EmployeeDDL}
                                                        setEmployeeDDL={setEmployeeDDL}
                                                        Department='0'
                                                        Designation='0'
                                                        IsPost={IsPost}
                                                    />
                                                </div> */}
                                                <div className="col-12 col-md-5 col-lg-3">
                                                    <DailyWorkSheetDesignationDDL
                                                        DailyWorkSheetDesignation={DailyWorkSheetDesignation}
                                                        setDailyWorkSheetDesignation={setDailyWorkSheetDesignation}
                                                        DDLFlag='Daily_Schedule_Wise'
                                                        IsPost={IsPost}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-5 col-lg-3">
                                                    <EmployeeDataDDL
                                                        EmployeeDDL={EmployeeDDL}
                                                        setEmployeeDDL={setEmployeeDDL}
                                                        Department={Department}
                                                        DailyWorkSheetDesignation={DailyWorkSheetDesignation}
                                                        // Designation={Designation}
                                                        IsPost={IsPost}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2 mt-lg-4 mt-md-4" >
                                                    {/* <button type="button" className="btn-search btn btn btn-secondary mr-1"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </button> */}
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th>{t('AdminDashboard.Table_Heading.Date')}</th>
                                                            <th>{t('EmployeeAndContractor.Karmachariche_Nav')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Garden_Name')}</th>
                                                            <th>{t('AdminDashboard.Admin_Titles.Total_Work')}</th>
                                                            <th>{t('AdminDashboard.Admin_Titles.Total_works_Completed')}</th>
                                                            {
                                                                searchName == 'Completed' || searchName == 'पूर्ण झालेली कामे' ?
                                                                    <></>
                                                                    :
                                                                    <th>{t('AdminDashboard.Admin_Titles.Pending_Work')}</th>

                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((i) =>
                                                                <tr>
                                                                    <td className="text-center">{i?.rowNum}</td>
                                                                    <td>{i.workAssignDate ? moment(i.workAssignDate).format('DD-MM-YYYY') : ''}</td>
                                                                    <td>{i.m_OfficeraName ? i.m_OfficeraName : '-'}</td>
                                                                    <td>{i.assetName ? i.assetName : '-'}</td>
                                                                    <td className="text-center">
                                                                        <button type="button" class="btn btn-sm px-2 py-0" style={{ background: 'grey', color: 'white' }}
                                                                            onClick={() => (
                                                                                handleDailyWorkTotalCountPopupShow(i, 'Total'),
                                                                                setDailyWorkPopupHeading(t('AdminDashboard.Admin_Titles.Total_Work'))
                                                                            )}
                                                                        >{i.kpiTotalCount ? i.kpiTotalCount : '0'}</button>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button type="button" class="btn btn-sm px-2 py-0" style={{ background: 'grey', color: 'white' }}
                                                                            onClick={() => (
                                                                                handleDailyWorkTotalCountPopupShow(i, 'Completed'),
                                                                                setDailyWorkPopupHeading(t('AdminDashboard.Admin_Titles.Total_works_Completed'))
                                                                            )}
                                                                        >{i.kpiCompletedCount ? i.kpiCompletedCount : '0'}</button>
                                                                    </td>
                                                                    {
                                                                        searchName == 'Completed' || searchName == 'पूर्ण झालेली कामे' ?
                                                                            <></>
                                                                            :
                                                                            <td className="text-center">

                                                                                <button type="button" class="btn btn-sm px-2 py-0" style={{ background: 'grey', color: 'white' }}
                                                                                    onClick={() => (
                                                                                        handleDailyWorkTotalCountPopupShow(i, 'Pending'),
                                                                                        setDailyWorkPopupHeading(t('AdminDashboard.Admin_Titles.Pending_Work'))
                                                                                    )}
                                                                                >{i.kpiPendingCount ? i.kpiPendingCount : '0'}</button>
                                                                            </td>

                                                                    }

                                                                </tr>
                                                            ) :
                                                                <td colSpan={15}>No Found data</td>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    showImagePopup ?
                        <ViewURL
                            Heading={ImageHeading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }
                {
                    DailyWorkPopupShow.show ?
                        <DailyWorkPopup
                            open={DailyWorkPopupShow.show}
                            cancelbtn={handleOnCloseClick}
                            DailyWorkPopupHeading={DailyWorkPopupHeading}
                            GridData={DailyWorkPopupShow.data}
                            PopUpApiFlag={DailyWorkPopupShow.flag}
                        // handleOnCloseClick={handleOnCloseClick}
                        // Flag={apiFlag}
                        // rowData={rowData}
                        />
                        : <></>
                }

            </div>

        </>

    )

}