import React, { useEffect, useState } from 'react'
import { WardnameHeaderDDL } from '../../../Components/CommonDDL/WardnameHeaderDDL'
import { useTranslation } from 'react-i18next'
import { ZoneNameDDL } from '../../../Components/CommonDDL/DashboardDDL/ZoneNameDDL'

export default function CommonDashboardWardNameDDL(props) {

    const { t } = useTranslation()

    const { flag, setZoneValue, setZoneLabel, ZLable, ZWard, WLable, HWard, WardName, setWardName, setWardValue, setWardLabel, Language, ZoneDDL, WardDDL, Countdata } = props
    // const [WardName, setWardName] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })
    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select Ward",
    })

    useEffect(() => {
        localStorage.setItem("ZoneNameDDLID", ZoneNameDDLID.ID)
    }, [ZoneNameDDLID])

    useEffect(() => {
        if (setWardValue !== undefined) {
            setWardValue(WardName.ID)
        }
        if (setWardLabel !== undefined) {
            setWardLabel(WardName.Label)
        }
    }, [WardName.ID])

    useEffect(() => {
        // console.log(WardName)
        if (setZoneValue !== undefined) {
            setZoneValue(ZoneNameDDLID.ID)
        }
        if (setZoneLabel !== undefined) {
            setZoneLabel(ZoneNameDDLID.Label)
        }
    }, [ZoneNameDDLID.ID])

    return (
        <>
            <div className="page-header pb-2">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <div className="page-header-title d-flex justify-content-between align-items-center">
                                <div className='col-5 col-md-6 col-lg-3'>

                                    {/* <h5 className=" ps-2 fs-5" >{t('Sidebar.Dashboard')}</h5> */}
                                    <p className="text-dark ps-3 mb-0" style={{fontSize:'20px'}}>{t('Dashboard.Titles.Master_Data')}</p>

                                </div>
                                <div className="col-md-6 col-lg-9 col-7" style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    <span className="pe-5 mt-2 fw-bold Total_count text-dark">
                                        Total Gardens : <span className="fw-bold Total_count">{Countdata.GardenTotalAllCount}</span>
                                    </span>

                                    <ZoneNameDDL
                                        ZoneNameDDLID={ZoneNameDDLID}
                                        setZoneNameDDLID={setZoneNameDDLID}
                                        Language={Language}
                                        ZLable={ZLable}
                                        ZWard={ZWard}
                                        ZoneDDL='DashboardZone'
                                    />

                                    <WardnameHeaderDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        WLable={WLable}
                                        HWard={HWard}
                                        flag={flag}
                                        Language={Language}
                                        M_ZoneID={ZoneNameDDLID.ID}
                                        WardDDL='DashboardWard'
                                    />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
