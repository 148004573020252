
import { useEffect } from 'react'
import { FinancialYearDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const FinancialYearHeaderDDL = (props) => {
    const { FinancialYear, setFinancialYear, YLable, HYear, flag } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(FinancialYearDDLAPI({ data }))
    }, [])

    const { FinancialYearData } = useSelector(state => state.FinancialYearDDLData)

    useEffect(() => {
        handleFinancialYearDDL()
    }, [FinancialYearData])

    const handleFinancialYearDDL = () => {
        if (FinancialYearData && FinancialYearData.table && FinancialYearData.table.length > 0) {
            let list = FinancialYearData.table.map((item, index) => ({
                value: item.id,
                label: item.financialYear,
            }))
            if (flag == 'Screen') {
                setFinancialYear({
                    DDL: list,
                    ID: HYear,
                    Label: YLable,
                })
            } else {
                setFinancialYear({
                    DDL: list,
                    ID: list[0].value,
                    Label: list[0].label,
                })
            }

        }
        else {
            setFinancialYear({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }



    return (
        <div className="form-group">

            <select
            style={{width:'10vw', textAlign:'center'}}
                onChange={(e) => {
                    e ?
                        setFinancialYear({ ...FinancialYear, ID: e.target.value, Label: e.target.name })
                        :
                        setFinancialYear({ ...FinancialYear, ID: 0, Label: "Select..." })
                }}
                // onChange={(e)=>console.log(e.target.name)}
            >
                {
                    FinancialYear.DDL.map((item) => (
                        <option value={item.value} name={item.label}>{item.label}</option>
                    ))
                }
            </select>

            {/* <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderBottomColor: 'white',
                        boxShadow: 'none',
                        backgroundColor: "transparent",
                        color: '#000000',
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    }),
                    singleValue: provided => ({
                        ...provided,
                        color: 'white'
                    })
                }}
                components={{
                    IndicatorSeparator: () => null
                }}
                isSearchable
                maxMenuHeight={150}
                value={{ value: FinancialYear.ID, label: FinancialYear.Label }}
                onChange={(e) => {
                    e ?
                        setFinancialYear({ ...FinancialYear, ID: e.value, Label: e.label })
                        :
                        setFinancialYear({ ...FinancialYear, ID: 0, Label: "Select..." })

                }}
                options={FinancialYear.DDL}
            /> */}

        </div>
    )
}