import React from "react";
import { useNavigate } from "react-router-dom";



export default function GardenMasterCard({ count, title, navLink, cardName, border, color, imgPath, MasterNavigate }) {
    const navigate = useNavigate()

    const handleGardenMasterCard = () => {
        navigate(navLink)
    }
    return (
        <div className="card py-0 shadow mb-0 " style={{ cursor: 'pointer' }} onClick={() => MasterNavigate(title)} >

            {/* <div className="card-body pt-1 pb-0 bg-light-green">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h4 className={color === 'red' ? "fw-normal text-red mb-0" : "fw-normal text-blue mb-0"}>{count}</h4>
                    </div>
                    <h4 className={color === 'red' ? "fw-normal text-red ImgBg my-0 bg-red-light" : "fw-normal text-blue ImgBg my-0 bg-blue-light"}>
                        <img style={{ width: "25px", height: '25px' }} src={imgPath} alt="" />
                    </h4>
                </div>
            </div> */}

            {/* <div className={color === 'red' ? "card-footer bg-red-light px-3" : "card-footer  bg-blue-light px-3"}>
                <div className={color === 'red' ? "row align-items-center text-red px-0" : "row align-items-center text-blue px-0"}>
                    <div className="col-8">
                        <p className="mb-0" style={{ whiteSpace: "nowrap", fontSize:'15px' }}><b>{title}</b></p>
                    </div>
                    <div className="col-4 text-end">
                        <h5 className={color === 'red' ? "fw-bold text-red mb-0 heading_size" : "fw-bold text-blue mb-0 heading_size"}>{count}</h5>
                    </div>
                </div>
            </div> */}

            <div className={`${color} card-footer px-3` }>
                <div className="bg_gray row align-items-center">
                    <div className="col-7">
                        <p className="mb-0" style={{ whiteSpace: "nowrap", fontSize: '15px' }}><b>{title}</b></p>
                    </div>
                    <div className="col-5 text-end">
                        <h4 className="text-white">{count}</h4>
                    </div>
                </div>
            </div>

        </div>
    )
}