import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FinancialYearHeaderDDL } from "../../../Components/CommonDDL/FinancialYearHeaderDDL";
import { MonthHeaderDDL } from "../../../Components/CommonDDL/MonthHeaderDDL";

export const GardenDailyWork = (props) => {
    const { ActiveButton, setActiveButton, handleButtonClick } = props
    // const [ActiveButton, setActiveButton] = useState('उद्यान दैनंदिन कामे');

    // const handleButtonClick = (index) => {
    //     setActiveButton(index);
    // };
    const { t } = useTranslation()
    const { DashboardHeading, handleGetFinancialYearID, flag, DashboardName,
        setYearValue, setMonthValue, setMonthLabel, setYearLabel, HYear, YLable, HMonth, MLable, LanLable, Lang, setWardValue, setWardLabel, setLangValue, setLangLabel, ProjectTypeId, HideYearMonthDDL, Language, setLanguage, Flag } = props


    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    useEffect(() => {
        handleGetFinancialYearID && handleGetFinancialYearID(FinancialYear, Month)
    }, [FinancialYear.ID, Month.ID])

    useEffect(() => {
        if (setYearValue !== undefined) {
            setYearValue(FinancialYear.ID)
        }
        if (setYearLabel !== undefined) {
            setYearLabel(FinancialYear.Label)
        }
    }, [FinancialYear.ID])

    useEffect(() => {
        if (setMonthValue !== undefined) {
            setMonthValue(Month.ID)
        }
        if (setMonthLabel !== undefined) {
            setMonthLabel(Month.Label)
        }
    }, [Month.ID])

    return (
        <>
            <div className="row px-3 pb-0 ">
                <div className="col-12 col-lg-2 ">
                    <FinancialYearHeaderDDL
                        FinancialYear={FinancialYear}
                        setFinancialYear={setFinancialYear}
                        YLable={YLable}
                        HYear={HYear}
                        flag={flag}
                    />
                </div>
                <div className="col-12 col-lg-2 px-0">
                    <MonthHeaderDDL
                        Month={Month}
                        setMonth={setMonth}
                        MLable={MLable}
                        HMonth={HMonth}
                        flag={flag}
                    />
                </div>

                <div className="col-12 col-lg-8 float-end">
                    <div className="btn-group text-center float-end" role="group" aria-label="Basic outlined example" style={{ border: '1px solid #cf4141', borderRadius: '5px', }}>
                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('उद्यान दैनंदिन कामे')}
                            className={ActiveButton == 'उद्यान दैनंदिन कामे' ? "btn fw-bold px-2 activebutton2 text-white d-flex border-ends2" : "text-black fw-bold btn px-2 d-flex hovernone border-ends2"}
                        > {t('Dashboard.Titles.Garden_Daily_Work')}</button>

                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('प्रशासनाने वाटप केलेली कामे')}
                            className={ActiveButton == 'प्रशासनाने वाटप केलेली कामे' ? "btn px-2 fw-bold activebutton2 text-white d-flex border-ends2" : "text-black fw-bold btn px-2 d-flex hovernone border-ends2"}
                            type="button">{t('Dashboard.Titles.Work_Assigned_by_Officials')}</button>
                        <button

                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('ठेकेदारनिहाय कामाची प्रगती')}
                            className={ActiveButton == 'ठेकेदारनिहाय कामाची प्रगती' ? "btn px-2 fw-bold activebutton2 text-white d-flex border-ends2" : "text-black fw-bold btn px-3 d-flex hovernone border-ends2"}
                            type="button">{t('Dashboard.Titles.Work_Progress_By_Contractor')}</button>

                        <button
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleButtonClick('पुन्हा वाटप केलेल्या कामाची प्रगती')}
                            className={ActiveButton == 'पुन्हा वाटप केलेल्या कामाची प्रगती' ? "btn px-2 fw-bold activebutton2 text-white d-flex " : "text-black fw-bold btn px-2 d-flex hovernone "}
                            type="button">{t('Dashboard.Titles.Work_Reopen_Progress_By_Contractor')}</button>
                    </div>
                </div>
            </div>


            {/* {
                        ActiveButton == "उद्यान दैनंदिन कामे" &&
                        <div className="row ">
                            <div className="col-12 " >
                                <h2 className=''>उद्यान दैनंदिन कामे</h2>
                            </div>
                        </div>
                    }


                    {
                        ActiveButton == "प्रशासनाने वाटप केलेली कामे" &&
                        <div className="row ">
                            <div className="col-12 " >
                                <h2 className=''>प्रशासनाने वाटप केलेली कामे</h2>
                            </div>
                        </div>
                    }


                    {
                        ActiveButton == "ठेकेदारनिहाय कामाची प्रगती" &&
                        <div className="row ">
                            <div className="col-12 " >
                                <h2 className=''>ठेकेदारनिहाय कामाची प्रगती</h2>
                            </div>
                        </div>
                    }

                    {
                        ActiveButton == "पुन्हा वाटप केलेल्या कामाची प्रगती" &&
                        <div className="row ">
                            <div className="col-12 " >
                                <h2 className=''>पुन्हा वाटप केलेल्या कामाची प्रगती</h2>
                            </div>
                        </div>
                    } */}


        </>
    )

}