import Header from "../../../../Components/Header/Header";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../Helper/Context";
import { DailyPreventiveWorkTableDataAPI } from "../../../../Redux/GardenDashboardSlice/DailyPreventiveWorkSlice";
import ViewURL from "../../../../Components/ViewURL/ViewURL";
import ReactSelect from "react-select"
import moment from "moment";
import { Loading } from "../../../../Helper/Loading";
import { WorkTypeDDL } from "../../../../Components/CommonDDL/WorkTypeDDL";
import { PenaltyWorkReportPDFTableDataAPI, PenaltyWorkReportTableDataAPI, PreventiveWorkReportTableDataAPI } from "../../../../Redux/ReportsSlice/PreventiveWorkReportSlice";
import { toastErrorr } from "../../../../Helper/ToastMessage";
import { useTranslation } from "react-i18next";
import { ZoneDataDDLS } from "../../../../Components/CommonDDL/ZoneDataDDLS";
import { AssetTypeDataDDL } from "../../../../Components/CommonDDL/AssetTypeDataDDL";
import { ContractorNameReportDDL } from "../../../../Components/CommonDDL/ContractorNameReportDDL";
import { WardNameMultiCheckBox } from "../../../../Components/CommonDDL/WardNameMultiCheckBox";
import { PreventiveWorkAssetNameDataDDL } from "../../../../Components/CommonDDL/PreventiveWorkAssetNameDDL";

export default function PreventiveWorkReport() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let Lang = searchParams.get("Lang")

    // console.log(ApiFlag)

    const d = new Date();
    let month = d.getMonth();

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [PreventiveWorkAssetNameDDL, setPreventiveWorkAssetNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [checkboxes, setCheckboxes] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WardIDData, setWardIDData] = useState([]);
    const [selected, setSelected] = useState([]);


    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImageHeading, setImageHeading] = useState('')
    const [PDFUrl, setPDFUrl] = useState('')
    const [Heading, setHeading] = React.useState(false)

    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: Lang,
    // })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const handleSearch = () => {
        setIsSearch(!IsSearch)
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }

    const handleClear = () => {
        setWardIDData([])
        setSelected([])
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: 'Select...',
        })
        setZoneName({
            ...ZoneName,
            ID: 0,
            Label: 'Select...',
        })
        setWorkType({
            ...WorkType,
            ID: 0,
            Label: 'Select...',
        })
        setContractorName({
            ...ContractorName,
            ID: 0,
            Label: 'Select...',
        })
        setPreventiveWorkAssetNameDDL({
            ...PreventiveWorkAssetNameDDL,
            ID: 0,
            Label: 'Select...',
        })
    }


    const handleGetPDFUrl = (pdfUrl) => {
        if (pdfUrl && pdfUrl != '') {
            window.open(pdfUrl)
        } else {
            toastErrorr("PDF not found")
        }
    }


    const handlePost = () => {
        setIsPost(!IsPost)
    }

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }


    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID, WorkType.ID])

    useEffect(() => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_ZoneID: ZoneName.ID,
            M_ContractorID: ContractorName.ID,
            M_WorkTypeID: WorkType.ID,
            M_AssetTypeID: AssetType.ID,
            M_AssetID: PreventiveWorkAssetNameDDL.ID,
            M_WardData: WardIDData && WardIDData.length > 0 ? WardIDData : '0',
            Flag: "Grid",
            M_UserID: UserID,
            token: token,
            Language: Language.Label,
            // handleGetPDFUrl: handleGetPDFUrl,
        }
        // if (WardName.ID != '0') {
        { dispatch(PenaltyWorkReportTableDataAPI({ data })) }
        // }

    }, [IsPost, IsSearch, IsClear])

    const { isLoading, tableData } = useSelector(state => state.PenaltyWorkReportTableData)


    const handleGetPDF = () => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_ZoneID: ZoneName.ID,
            M_ContractorID: ContractorName.ID,
            M_WorkTypeID: WorkType.ID,
            M_AssetTypeID: AssetType.ID,
            M_AssetID: PreventiveWorkAssetNameDDL.ID,
            M_WardData: WardIDData && WardIDData.length > 0 ? WardIDData : '0',
            Flag: "PDF_Report",
            M_UserID: UserID,
            token: token,
            handleGetPDFUrl: handleGetPDFUrl,

        }
        dispatch(PenaltyWorkReportPDFTableDataAPI({ data }))
    }

    const { PDFtableData, isPDFLoading } = useSelector(state => state.PenaltyWorkReportPDFTableData)

    useEffect(() => {
        setSelected([])
        setWardIDData([])
    }, [ZoneName.ID])

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div id="" className="content1 content">
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob">DAILY PREVENTIVE WORK */}

                                        {/* <span className="float-end fs-6">
                                                    <Select
                                                        // isClearable
                                                        // isRtl={isRtl}
                                                        isSearchable
                                                        maxMenuHeight={150}
                                                        value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                        onChange={(e) => {
                                                            e ?
                                                                setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                                :
                                                                setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                        }}
                                                        options={SelectWard.DDL}
                                                    />

                                                </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}

                        <div className="data_mob">
                            <div className="row m-1 pb-4 master_border">
                                <div className="col-8">
                                    <h5 className="ps-1 py-2">{t('Sidebar.Preventive_Work_Report')}</h5>
                                </div>
                                {/* {
                                    isExportLoading ?
                                        <button type="button" className="btn btn-primary float-end mr-1 mb-1 ml-1" disabled>
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />Loading</button>
                                        :
                                        ExportTableData && ExportTableData.table && ExportTableData.table.length > 0 &&
                                        <PreventiveWorkReportExport
                                            // ExcelData={ExportTableData}
                                            name={t('AdminDashboard.Admin_Sidebar_Name.Work')}
                                            ExcelData={tableData}
                                            WardName={WardName}
                                            FromDate={FromDate}
                                            ToDate={ToDate}
                                        // searchName={searchName}
                                        />
                                } */}
                                {/* <div className="col-4 mt-2">
                                    <PreventiveWorkReportExport
                                        name='Preventive Work Report'
                                       
                                    />
                                    <button type="button" className="btn btn-outline-primary float-end mr-1 mb-2 pt-1 pb-1 pl-3 pr-3"
                                        onClick={handleOpenPdf}
                                    >PDF</button>
                                </div> */}
                                {
                                    (tableData && tableData.table && tableData.table.length > 0 && WardIDData.length > 0) &&
                                    <div className='col-4 mt-2'>

                                        {
                                            isPDFLoading ?
                                                <button type="button" style={{ width: '7em' }} className="btn btn-danger float-end d-flex" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px", marginTop: '3px' }}
                                                    />Loading</button>
                                                :
                                                // PDFtableData && PDFtableData.table && PDFtableData.table.length > 0 ?
                                                <>
                                                    {/* <button type="button" className="btn btn-primary float-end mr-1">Export</button> */}
                                                    <button type="button" className="btn btn-outline-danger float-end mr-1 mb-2 px-2"
                                                        disabled={WardIDData == 0 ? "disabled" : ''}
                                                        onClick={() => handleGetPDF()}
                                                    >PDF</button>
                                                </>
                                            // :
                                            // ''
                                        }
                                    </div>
                                }
                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>{t('Dashboard.Work_Assigned_by_Officials.From_Date')} </b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Work_Assigned_by_Officials.To_Date')} </b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                            min={FromDate} />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ZoneDataDDLS
                                                        ZoneName={ZoneName}
                                                        setZoneName={setZoneName}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkTypeDDL
                                                        WorkType={WorkType}
                                                        setWorkType={setWorkType}
                                                        Language={Language}
                                                    />
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <label for=""><b>Contractor Name </b></label>

                                                    <ContractorNameReportDDL
                                                        ContractorName={ContractorName}
                                                        setContractorName={setContractorName}
                                                        ZoneName={ZoneName}
                                                        WorkType={WorkType}
                                                        AssetType={AssetType}
                                                        setSelected={setSelected}
                                                    />

                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <label for=""><b>Ward Name </b></label>
                                                    <WardNameMultiCheckBox
                                                        ContractorName={ContractorName}
                                                        WardIDData={WardIDData}
                                                        setWardIDData={setWardIDData}
                                                        selected={selected}
                                                        setSelected={setSelected}
                                                        APIChangeFlag="APIChangeFlag"
                                                        ZoneName={ZoneName}
                                                        WorkType={WorkType}
                                                        AssetType={AssetType}
                                                        Language={Language}
                                                        Flag='ContractorWiseWardSportWork'

                                                    />
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="form-group ">
                                                        <label className="fw-bold">Asset Name</label>
                                                        <PreventiveWorkAssetNameDataDDL
                                                            PreventiveWorkAssetNameDDL={PreventiveWorkAssetNameDDL}
                                                            setPreventiveWorkAssetNameDDL={setPreventiveWorkAssetNameDDL}
                                                            ZoneName={ZoneName}
                                                            WorkType={WorkType}
                                                            AssetType={AssetType}
                                                            WardIDData={WardIDData}
                                                            ContractorName={ContractorName}
                                                            selected={selected}
                                                            setSelected={setSelected}
                                                            Language={Language}
                                                            DDLFlag='ContractorWiseAsset'
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-12 mt-lg-2">
                                                    <div className="float-right">
                                                        <button type="button" className="btn-search btn btn-primary mx-2"
                                                            onClick={() => handleSearch()}
                                                        >
                                                            Search
                                                        </button>
                                                        <button type="button" className="btn-clear btn btn-primary" style={{ backgroundColor: 'orange' }}
                                                            onClick={handleClear}
                                                        >
                                                            Clear</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1 tableFixHead" style={{ height: '28em' }}>
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            {/* <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th style={{ width: "10em" }}>{t('Dashboard.Work_Assigned_by_Officials.Assign_Date')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Asset_Type')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Asset_Name')}</th>
                                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')}</th>
                                                            <th>{t('Sidebar.Before_Work_Photo')}</th>
                                                            <th>{t('Sidebar.Work_Done_Date')}</th>
                                                            <th>{t('Sidebar.After_Work_Photo')}</th>
                                                            <th>{t('Sidebar.Work_Assign_By')}</th> */}
                                                            <th>Sr.No</th>
                                                            <th>Sub Work Name</th>
                                                            <th>Work Name</th>
                                                            <th>BenchMark</th>
                                                            <th>Defaulter</th>
                                                            <th>Penalty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                                                                    <td>{item.workSubTypeName ? item.workSubTypeName : '-'}</td>
                                                                    <td>{item.workName ? item.workName : '-'}</td>
                                                                    <td>{item.benchMark ? item.benchMark : '-'}</td>
                                                                    {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                                                    <td>{item.defualter ? item.defualter : '-'}</td>
                                                                    {/* <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.beforePhotoPath == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye"
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => (
                                                                                            handleShowImage(item.beforePhotoPath),
                                                                                            setHeading("Before Work Photo")
                                                                                        )}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td> */}
                                                                    <td>{item.totalPenaltyAmount ? item.totalPenaltyAmount : '-'}</td>
                                                                    {/* <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.afterPhotoPath == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye"
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => (
                                                                                            handleShowImage(item.afterPhotoPath),
                                                                                            setHeading("After Work Photo")
                                                                                        )}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td> */}
                                                                    {/* <td>{item.assingBy ? item.assingBy : '-'}</td> */}
                                                                </tr>
                                                            ) :
                                                                <td colSpan={15}>No Found data</ td>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            } */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {
                    showImagePopup ?
                        <ViewURL
                            Heading={ImageHeading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''


                }

            </div>

        </>

    )

}